import React, {useEffect, useState} from "react";
import {Button, Form, Stack, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Formik, FormikValues} from "formik";
import * as yup from "yup";
import ValidatedFormControl from "components/ValidatedFormControl";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {register, selectUserError, selectUserLoading} from "redux/features/user/slice";
import LoadingModal from "components/LoadingModal";

const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
});

const SignUp = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectUserLoading);
    const userError = useAppSelector(selectUserError);

    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (!userError) {
            setError('');
        } else if (userError.code === 'auth/invalid-email') {
            setError('Invalid email');
        } else if (userError.code === 'auth/weak-password') {
            setError('Your password is not safe, make it at least 6 characters long');
        } else if (userError.code === 'auth/email-already-in-use') {
            setError('You already have an account, if you don\'t remember your password use the "Forgot password" link');
        } else {
            setError(userError.message);
        }
    }, [userError])


    const doLogin = async (values: FormikValues, actions: any) => {
        dispatch(register({
            email: values.email,
            password: values.password,
        }));
        actions.setSubmitting(false);
    };

    return (
            <>
                <Modal
                        show={!!error}
                        onHide={() => setError(null)}
                        backdrop="static"
                        keyboard={false}
                        centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>An error happened</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setError(null)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <LoadingModal loading={loading}/>
                <Formik
                        validationSchema={schema}
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={doLogin}>
                    {(formikProps) => (
                            <Form noValidate onSubmit={formikProps.handleSubmit}>
                                <Row>
                                    <Col/>
                                    <Col xs={3}>
                                        <Stack gap={1}>
                                            <div style={{height: '30px'}}></div>
                                            <div style={{paddingLeft: '20px'}}>
                                                <h1>Welcome</h1>
                                                <p>Create your free account</p>
                                            </div>
                                            <div style={{height: '30px'}}></div>
                                            <ValidatedFormControl label='Email address' type='email'
                                                                  placeholder='Enter your email address'
                                                                  formikProps={formikProps} field='email'/>
                                            <ValidatedFormControl label='Password' type='password'
                                                                  placeholder='Choose your password'
                                                                  formikProps={formikProps} field='password'/>
                                            <Form.Group className="mb-3">
                                                <div className="d-grid gap-2">
                                                    <Button variant="primary" type="submit" size="lg"
                                                            disabled={formikProps.isSubmitting}>
                                                        {formikProps.isSubmitting ? 'Signing up' : 'Sign up'}
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col/>
                                </Row>
                                <Row>
                                    <Col/>
                                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                                        I already have an account. <Link to='/sign-in'>Sign in</Link>
                                    </Col>
                                    <Col/>
                                </Row>
                            </Form>
                    )}
                </Formik>
            </>
    );
}

export default SignUp;
