import React, {useEffect, useState} from "react";
import {Button, Form, Stack, Row, Col, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Formik, FormikValues} from "formik";
import * as yup from "yup";
import ValidatedFormControl from "components/ValidatedFormControl";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {clearError, login, selectUserLoading, selectUserError} from "redux/features/user/slice";
import LoadingModal from "components/LoadingModal";

const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
});

const SignIn = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectUserLoading);
    const userError = useAppSelector(selectUserError);

    const [error, setError] = useState('');
    useEffect(() => {
        if (!userError) {
            setError('');
        } else if (userError.code === 'auth/user-not-found') {
            setError('Wrong password');
        } else if (userError.code === 'auth/wrong-password') {
            setError('Wrong password');
        } else if (userError.code === 'auth/too-many-requests') {
            setError('Too many requests, please wait a few minutes');
        } else {
            setError(userError.message);
        }
    }, [userError])

    const doLogin = async (values: FormikValues, actions: any) => {
        dispatch(login({
            email: values.email,
            password: values.password,
        }));
        actions.setSubmitting(false);
    };

    return (
            <>
                <Modal
                        show={!!error}
                        onHide={() => dispatch(clearError())}
                        backdrop="static"
                        keyboard={false}
                        centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>An error happened</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => dispatch(clearError())}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <LoadingModal loading={loading}/>
                <Formik
                        validationSchema={schema}
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={doLogin}
                >
                    {(formikProps) => (
                            <Form noValidate onSubmit={formikProps.handleSubmit}>
                                <Row>
                                    <Col/>
                                    <Col xs={3}>
                                        <Stack gap={1}>
                                            <div style={{height: '30px'}}></div>
                                            <div style={{paddingLeft: '20px'}}>
                                                <h1>Welcome</h1>
                                                <p>Sign in to continue</p>
                                            </div>
                                            <div style={{height: '30px'}}></div>
                                            <ValidatedFormControl label='Email address' type='email'
                                                                  placeholder='Enter your email address'
                                                                  formikProps={formikProps} field='email'/>
                                            <ValidatedFormControl label='Password' type='password'
                                                                  placeholder='Enter your password'
                                                                  formikProps={formikProps} field='password'>
                                                <div style={{float: 'right'}}>
                                                    <Link to='/forgot-password'>Forgot Password?</Link>
                                                </div>
                                            </ValidatedFormControl>
                                            <Form.Group className="mb-3">
                                                <div className="d-grid gap-2">
                                                    <Button variant="primary" type="submit" size="lg"
                                                            disabled={formikProps.isSubmitting}>
                                                        {formikProps.isSubmitting ? 'Signing in' : 'Sign In'}
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col/>
                                </Row>
                                <Row>
                                    <Col/>
                                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                                        I'm a new user. <Link to='/sign-up'>Sign up</Link>
                                    </Col>
                                    <Col/>
                                </Row>
                            </Form>
                    )}
                </Formik>
            </>
    );
}

export default SignIn;
