import Graph from "./Graph";
import Lap from "redux/features/firestore/sessions/Lap";
import {ShownLap} from "redux/features/firestore/sessions/slice";

const SpeedDiffGraph = () => {
    return Graph((index: number, fastestLap: Lap, shownLap: ShownLap): number => {
                let fastestLocation = fastestLap.locations![index].location;
                let lapLocation = shownLap.calculatedLocations![index].location;

                return lapLocation.speedKpH - fastestLocation.speedKpH;
            },
            (value: number) => `${value.toFixed( 2)}`,
            'km/h',
            false, 0, true);
}
export default SpeedDiffGraph;
