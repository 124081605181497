import React, {useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";
import AuthProvider from "./redux/features/user/AuthProvider";
import {Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {User} from "firebase/auth";
import {loggedIn, loggedOut, sendVerificationEmail} from "./redux/features/user/slice";
import RootNavigation from "pages/RootNavigation";
import 'mapbox-gl/dist/mapbox-gl.css';
import { PersistGate } from "redux-persist/integration/react";
import UserProvider from "./redux/features/firestore/user/UserProvider";

function App() {
    useEffect(() => {
        AuthProvider.instance.auth.onAuthStateChanged((user: User | null) => {
            if (user === null) {
                store.dispatch(loggedOut());
            } else {
                UserProvider.instance.getUser(user).then(u => {
                    store.dispatch(loggedIn(u));
                })
                if (!user.emailVerified) {
                    store.dispatch(sendVerificationEmail());
                }
            }
        })
    }, []);

    return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <RootNavigation/>
                </PersistGate>
            </Provider>
    );
}

export default App;
