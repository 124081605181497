import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import Icon from "react-web-vector-icons";
import {useAppSelector} from "redux/hooks";
import {
    selectUser
} from "redux/features/user/slice";
import React from "react";
import {useNavigate} from "react-router-dom";

const AppBar = () => {
    const user = useAppSelector(selectUser);

    const navigate = useNavigate();
    return (
            <>
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <Navbar.Collapse>
                            <Nav>
                                <NavDropdown title={<Icon font='MaterialIcons' name='menu' color='white'
                                                          size={20}/>}
                                             id="nav-dropdown">
                                    <NavDropdown.Item disabled>{user.email}</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => navigate('/members/account-settings')}>My account</NavDropdown.Item>
                                    {user.allowLive &&
                                        <NavDropdown.Item onClick={() => navigate('/members/live-settings')}>Live settings</NavDropdown.Item>}
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                        <Navbar.Brand href="/members/" className="justify-content-end">
                            <img
                                    alt=""
                                    src={process.env.PUBLIC_URL + "/icon/web.svg"}
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                            />{' '}
                            Fierro a Fondo
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            </>
    );
}

export default AppBar;
