import {PathLayer} from "@deck.gl/layers/typed";
import {Layer} from "@deck.gl/core/typed";
import ProcessedLocation from "redux/features/firestore/sessions/ProcessedLocation";
import LapTools from "redux/features/firestore/sessions/LapTools";
import {useControl} from "react-map-gl";
import {MapboxOverlay, MapboxOverlayProps} from "@deck.gl/mapbox/typed";
import {useAppSelector} from "redux/hooks";
import {selectFastestShownLap, selectShownLaps,} from "redux/features/firestore/sessions/slice";
import {useEffect, useState} from "react";
import {LapLayerData} from "./LapLayerData";

function DeckGLOverlay(props: MapboxOverlayProps & {
    interleaved?: boolean;
}) {
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
    overlay.setProps(props);
    return null;
}

const LapsLayer = () => {
    const fastestLap = useAppSelector(selectFastestShownLap);
    const shownLaps = useAppSelector(selectShownLaps);

    const [layer, setLayer] = useState<Layer | null>(null);
    const [data, setData] = useState<LapLayerData[]>([]);

    useEffect(() => {
        const currentData: LapLayerData[] = [];
        if (fastestLap && shownLaps) {
            for (const shownLap of shownLaps) {
                const {r, g, b} = LapTools.hueToRgb(shownLap.lap.hue);
                currentData.push({
                    path: shownLap.lap.locations!.map((l: ProcessedLocation) => [l.location.longitude, l.location.latitude]),
                    color: [r, g, b, 40],
                    fastest: LapTools.Equals(shownLap.lap, fastestLap),
                });
            }
        }
        setData(currentData);
    }, [fastestLap, shownLaps]);

    useEffect(() => {
        setLayer(new PathLayer({
            id: LapsLayer.name,
            data: data,
            widthMinPixels: 1,
            getPath: d => d.path,
            getColor: d => d.color,
            getWidth: d => d.fastest ? 2 : 1
        }));
    }, [data])

    return <DeckGLOverlay layers={[layer]}/>
}
export default LapsLayer;
