import ProcessedLocation from "../ProcessedLocation";
import ProcessedLocationTools from "./ProcessedLocationTools";

class ProcessedLap {
    readonly locations: ProcessedLocation[] = [];
    distanceInMeters: number = 0;
    timeInSeconds: number = 0;

    completeLap(lastLocation: ProcessedLocation) {
        this.distanceInMeters -= ProcessedLocationTools.distanceInMetersBetweenLocations(
            this.locations[this.locations.length - 2].location,
            this.locations[this.locations.length - 1].location,
        );
        this.locations.pop();

        this.addLocation(lastLocation);
    }

    addLocation(currentLocation: ProcessedLocation) {
        if (this.locations.length > 0) {
            this.distanceInMeters += ProcessedLocationTools.distanceInMetersBetweenLocations(
                this.locations[this.locations.length - 1].location,
                    currentLocation.location,
                );
        }
        this.locations.push(currentLocation);
        this.timeInSeconds =
            (currentLocation.location.unixTimestampUTC -
                this.locations[0].location.unixTimestampUTC) /
            1000;
    }
}

export default ProcessedLap;
