import {useEffect, useState} from "react";
import {useControl} from "react-map-gl";
import {useAppSelector} from "redux/hooks";
import {
    selectFastestShownLap,
    selectHighlightLocation,
    selectShownLaps,
    ShownLap
} from "redux/features/firestore/sessions/slice";
import {MapboxOverlay, MapboxOverlayProps} from "@deck.gl/mapbox/typed";
import {Layer} from "@deck.gl/core/typed";
import {GeoJsonLayer} from "@deck.gl/layers/typed";
import LapTools from "redux/features/firestore/sessions/LapTools";
import Lap from "redux/features/firestore/sessions/Lap";

function DeckGLOverlay(props: MapboxOverlayProps & {
    interleaved?: boolean;
}) {
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
    overlay.setProps(props);
    return null;
}

const HighlightLocationLayer = () => {
    const shownLaps : ShownLap[] | null = useAppSelector(selectShownLaps);
    const fastestShownLap : Lap | null = useAppSelector(selectFastestShownLap);
    const highlightLocation: number | null = useAppSelector(selectHighlightLocation);

    const [layer, setLayer] = useState<Layer | undefined>(undefined);

    useEffect(() => {
        if (highlightLocation !== null && fastestShownLap && shownLaps) {
            const data: any[] = [];
            for (const shownLap of shownLaps) {
                const averagedProcessedLocation = shownLap!.calculatedLocations![highlightLocation];
                const location = averagedProcessedLocation.location;
                data.push({
                    "type": "Feature",
                    "geometry": {
                        "type": "Point",
                        "coordinates": [location.longitude, location.latitude]
                    },
                    "properties": {
                        "color": LapTools.hueToRgb(shownLap.lap.hue),
                        "radius": LapTools.Equals(fastestShownLap, shownLap.lap) ? 2 : 1.5,
                    }
                });
                // if(!LapTools.Equals(shownLap.lap, fastestShownLap)) {
                //     if (averagedProcessedLocation.closest) {
                //         data.push({
                //             "type": "Feature",
                //             "geometry": {
                //                 "type": "Point",
                //                 "coordinates": [averagedProcessedLocation.closest.location.longitude, averagedProcessedLocation.closest.location.latitude]
                //             },
                //             "properties": {
                //                 "color": {r: 255, g: 255, b: 255},
                //                 "radius": 1,
                //             }
                //         });
                //     }
                //     if (averagedProcessedLocation.secondClosest) {
                //         data.push({
                //             "type": "Feature",
                //             "geometry": {
                //                 "type": "Point",
                //                 "coordinates": [averagedProcessedLocation.secondClosest.location.longitude, averagedProcessedLocation.secondClosest.location.latitude]
                //             },
                //             "properties": {
                //                 "color": {r: 0, g: 0, b: 0},
                //                 "radius": 1,
                //             }
                //         });
                //     }
                // }
            }

            const currentLayer = new GeoJsonLayer({
                id: 'Highlight Locations',
                data: data,
                stroked: true,
                getFillColor: (d) => [d.properties!.color.r, d.properties!.color.g, d.properties!.color.b, 100],
                getPointRadius: d => d.properties!.radius,
                getLineColor: (d) => [d.properties!.color.r, d.properties!.color.g, d.properties!.color.b, 255],
                getLineWidth: .5,
            });
            setLayer(currentLayer);
        } else {
            setLayer(undefined);
        }
    }, [highlightLocation, fastestShownLap, shownLaps])


    return (<DeckGLOverlay layers={[layer]}/>)
}

export default HighlightLocationLayer;
