import { initializeApp } from "firebase/app";

const firebaseBackend = initializeApp({
    apiKey: "AIzaSyDRv4554xawgFgg1ZNZrUWZydKBvxBlXlg",
    authDomain: "fierro-a-fondo.firebaseapp.com",
    projectId: "fierro-a-fondo",
    storageBucket: "fierro-a-fondo.appspot.com",
    messagingSenderId: "398397549624",
    appId: "1:398397549624:web:4d2e1c0996c3a96fe88acd",
    measurementId: "G-DH1W7PWDW1",
});
export default firebaseBackend;
