import React, {useEffect, useState} from "react";
import {useAppSelector} from "redux/hooks";
import {
    selectHighlightSessionLocation,
    selectSelectedSession
} from "redux/features/firestore/sessions/slice";
import Session from "redux/features/firestore/sessions/Session";
import RealTimeRaceProcessing from "redux/features/firestore/sessions/Processing/RealTimeRaceProcessing";
import {selectSelectedTrack} from "redux/features/firestore/tracks/slice";
import ProcessedLocation from "redux/features/firestore/sessions/ProcessedLocation";
import {Stack} from "react-bootstrap";
import {TextField} from "@mui/material";

const Info = () => {
    const selectedSession: Session = useAppSelector(selectSelectedSession);
    const selectedTrack = useAppSelector(selectSelectedTrack);
    const highlightSessionLocation: number | null = useAppSelector(selectHighlightSessionLocation);
    const [processedLocation, setProcessedLocation] = useState<ProcessedLocation | undefined>(undefined);
    const [log, setLog] = useState<string>('');

    useEffect(() => {
        if (selectedSession && highlightSessionLocation) {
            const realTimeProcessing = new RealTimeRaceProcessing(selectedTrack);
            for (let i = 0; i < highlightSessionLocation - 1; i++) {
                realTimeProcessing.addLocation(selectedSession.locations[i]);
            }
            let currentLog = '';
            realTimeProcessing.onLog = (m) => {
                if (currentLog === '') {
                    currentLog += m;
                } else {
                    currentLog += '\n' + m;
                }
            };
            const currentProcessedLocation = realTimeProcessing.addLocation(selectedSession.locations[highlightSessionLocation]);
            setProcessedLocation(currentProcessedLocation);
            setLog(currentLog);
        }
    }, [selectedSession, highlightSessionLocation]);

    return (
            <div style={{margin: '15px'}}>
                <Stack direction='vertical' gap={3}>
                    <TextField label="Log" variant="outlined" value={log} multiline/>
                    <TextField label="Processed" variant="outlined" value={JSON.stringify(processedLocation, null, '\t')} multiline/>
                </Stack>
            </div>
    );
}

export default Info;
