import {useEffect, useRef, useState} from "react";
import Map, {MapProvider, Marker, NavigationControl} from "react-map-gl";
import {LatLng} from "redux/features/firestore/tracks/Track";
import {useAppSelector} from "redux/hooks";
import {selectSelectedTrack} from "redux/features/firestore/tracks/slice";
import LapsLayer from "./TrackMap/LapsLayer";
import HighlightLocationLayer from "./TrackMap/HighlightLocationLayer";
import RangedLapsLayer from "./TrackMap/RangedLapsLayer";

const mapStyles = [
    'mapbox://styles/mapbox/satellite-v9',
    'mapbox://styles/mapbox/streets-v12',
    'mapbox://styles/mapbox/outdoors-v12',
    'mapbox://styles/mapbox/light-v11',
    'mapbox://styles/mapbox/dark-v11',
    'mapbox://styles/mapbox/satellite-streets-v12',
];

const TrackMap = () => {
    const selectedTrack = useAppSelector(selectSelectedTrack);

    const [location, setLocation] = useState<LatLng | undefined>(undefined);
    const [startRotation, setStartRotation] = useState<number>(0);

    useEffect(() => {
        if (selectedTrack) {
            setLocation(selectedTrack.startLocation);
            setStartRotation(selectedTrack.startRotation);
        } else {
            setLocation(undefined);
        }
    }, [selectedTrack])

    const mapRef = useRef<any>();
    useEffect(() => {
        const repaintInterval = setInterval(() => {
            mapRef.current?.resize();
        }, 100)
        return () => {
            clearInterval(repaintInterval);
        }
    }, []);

    return (
            <>
                {
                        location && (
                                <MapProvider>
                                    <Map id='trackMap'
                                         ref={mapRef}
                                         style={{
                                             position: 'relative',
                                         }}
                                         initialViewState={{
                                             longitude: location!.longitude,
                                             latitude: location!.latitude,
                                             zoom: 17
                                         }}
                                         mapStyle={mapStyles[0]}
                                         mapboxAccessToken={process.env.REACT_APP_MAPBOX}
                                         // onMouseMove={mouseMoved}
                                    >
                                        <NavigationControl/>
                                        <Marker longitude={location!.longitude} latitude={location!.latitude}
                                                rotation={startRotation} rotationAlignment='map'>
                                            <img src='/map/startMarker.png' alt=''/>
                                        </Marker>
                                        <LapsLayer/>
                                        <RangedLapsLayer/>
                                        <HighlightLocationLayer/>
                                    </Map>
                                </MapProvider>
                        )
                }
            </>
    );
}

export default TrackMap;
