import React from "react";
import Toast from "react-bootstrap/Toast";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {selectError, setToastError} from "./slice";
import {ToastContainer} from "react-bootstrap";

const AllToasts = () => {
    const dispatch = useAppDispatch();
    const error = useAppSelector(selectError);

    return (
            <ToastContainer className="p-3" position='bottom-end'>
                <Toast show={!!error} onClose={() => dispatch(setToastError(undefined))} bg='danger'>
                    <Toast.Header>
                        <strong className="me-auto">An error happened</strong>
                    </Toast.Header>
                    <Toast.Body>{error}</Toast.Body>
                </Toast>
            </ToastContainer>
    );
}

export default AllToasts;
