import React, {useEffect, useState} from "react";
import {Stack, Row, Col, Modal, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import ErrorModal from "components/ErrorModal";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    clearVerificationEmailSent, logout, reloadUser, selectUser,
    selectUserError,
    selectVerificationEmailSent, sendVerificationEmail
} from "redux/features/user/slice";

const NotVerified = () => {
    const dispatch = useAppDispatch();
    const userError = useAppSelector(selectUserError);

    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if(!userError) {
            setError('');
        } else {
            setError(userError.message);
        }
    }, [userError])


    const closeEmailSent = () => {
        dispatch(clearVerificationEmailSent());
    };

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        const dispatchReloadUser = async () => {
            dispatch(reloadUser());
            interval = setTimeout(() => dispatchReloadUser(), 3000);
        };
        dispatchReloadUser().catch(console.error);
        return () => {
            if (interval != null) {
                clearTimeout(interval);
            }
        };
    }, [dispatch]);

    const verificationEmailSent = useAppSelector(selectVerificationEmailSent);
    const user = useAppSelector(selectUser);
    return (
            <>
                <ErrorModal error={error} setError={setError}/>
                <Modal show={verificationEmailSent} onHide={closeEmailSent}>
                    <Modal.Header closeButton>
                        <Modal.Title>Email sent</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        We sent you an email with a link to verify your account. If you didn't receive it make sure to
                        check your spam folder.
                    </Modal.Body>
                </Modal>
                <Row>
                    <Col/>
                    <Col xs={3}>
                        <Stack gap={1}>
                            <div style={{height: '30px'}}></div>
                            <div style={{paddingLeft: '20px'}}>
                                <h1>Welcome</h1>
                                <p>{user!.email}</p>
                            </div>
                            <div style={{height: '30px'}}></div>
                            <div>
                                <p>
                                    You need to verify your account, please check your email ({user!.email})
                                </p>
                            </div>
                        </Stack>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <Col/>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        I cannot find the verification email.{' '} <Link onClick={() => dispatch(sendVerificationEmail())} to='#'>Send it again</Link>
                    </Col>
                    <Col/>
                </Row>
                <Row>
                    <Col/>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Button onClick={()=> dispatch(logout())} >Logout</Button>
                    </Col>
                    <Col/>
                </Row>
            </>
    );
}

export default NotVerified;
