import {Link, useParams} from "react-router-dom";
import {Stack} from "react-bootstrap";
import Icon from "react-web-vector-icons";
import React, {useEffect, useState} from "react";
import {clearSelectedTrack, selectSelectedTrack, selectTrackById} from "redux/features/firestore/tracks/slice";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    clearSelectedSessions,
    setHighlightLocation,
    setHighlightRange,
} from "redux/features/firestore/sessions/slice";
import "components/dock/Dock.scss";
import {defineCustomElements} from "igniteui-dockmanager/loader";
import {IgcDockManagerComponent, IgcDockManagerPaneType, IgcSplitPaneOrientation} from "igniteui-dockmanager";
import DockPane from "components/dock/DockPane";
import TrackMap from "./TrackMap";
import SessionsList from "./SessionsList";
import SpeedGraph from "./Graphs/SpeedGraph";
import Controls from "./Controls";
import TimeDiffGraph from "./Graphs/TimeDiffGraph";
import SpeedDiffGraph from "./Graphs/SpeedDiffGraph";

/* eslint-disable */
declare global {
    namespace JSX {
        // tslint:disable-next-line:interface-name
        // noinspection JSUnusedGlobalSymbols
        interface IntrinsicElements {
            "igc-dockmanager": any;
        }
    }
}
/* eslint-enable */

defineCustomElements().catch(e => console.error(e));

const Track = () => {
    const {trackId} = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(selectTrackById(trackId!));
    }, [dispatch, trackId]);
    const selectedTrack = useAppSelector(selectSelectedTrack);

    const [panes, setPanes] = useState<Map<string, DockPane> | undefined>(undefined);
    useEffect(() => {
        if (selectedTrack) {
            const p = new Map();
            p.set(Controls.name, new DockPane(
                    Controls.name,
                    'Controls',
                    <Controls/>,
            ));
            p.set(TrackMap.name, new DockPane(
                    TrackMap.name,
                    'Map',
                    <TrackMap/>,
            ));
            p.set(SessionsList.name, new DockPane(
                    SessionsList.name,
                    'Sessions',
                    <SessionsList/>
            ));
            p.set(SpeedGraph.name, new DockPane(
                    SpeedGraph.name,
                    'Speed',
                    <SpeedGraph/>
            ));
            p.set(TimeDiffGraph.name, new DockPane(
                    TimeDiffGraph.name,
                    'Time diff',
                    <TimeDiffGraph/>
            ));
            p.set(SpeedDiffGraph.name, new DockPane(
                    SpeedDiffGraph.name,
                    'Speed diff',
                    <SpeedDiffGraph/>
            ));
            setPanes(p);
        } else {
            setPanes(undefined);
        }
    }, [selectedTrack]);

    const [panesList, setPanesList] = useState<DockPane[] | undefined>(undefined);
    useEffect(() => {
        if (panes) {
            setPanesList(Array.from(panes.values()));
        } else {
            setPanesList(undefined);
        }
    }, [panes])

    useEffect(() => {
        if (panes) {
            const dockManager = document.getElementById("dockManager") as IgcDockManagerComponent;
            dockManager.layout = {
                rootPane: {
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.horizontal,
                    panes: [
                        {
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 20,
                            panes: [
                                panes.get(SessionsList.name)!.pane,
                            ]
                        },
                        {
                            ...panes.get(TrackMap.name)!.pane,
                            size: 40,
                        },
                        {
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 40,
                            panes: [
                                {
                                    ...panes.get(Controls.name)!.pane,
                                    size: 13,
                                },
                                {
                                    ...panes.get(SpeedGraph.name)!.pane,
                                    size: 29,
                                },
                                {
                                    ...panes.get(SpeedDiffGraph.name)!.pane,
                                    size: 29,
                                },
                                {
                                    ...panes.get(TimeDiffGraph.name)!.pane,
                                    size: 29,
                                },
                            ]
                        },
                    ]
                },
                floatingPanes: undefined,
            };
        }
    }, [panes]);

    return <>
        <Stack direction='vertical' style={{position: 'fixed', width: '100%', height: '95%'}}>
            <Stack direction='horizontal' gap={3}>
                <div></div>
                <Link to='..' style={{textDecoration: 'none'}} onClick={() => {
                    dispatch(clearSelectedTrack());
                    dispatch(clearSelectedSessions());
                    dispatch(setHighlightRange(null))
                    dispatch(setHighlightLocation(null))
                }}>
                    <Icon font='MaterialIcons' name='arrow-back' color='black' size={20}/>
                </Link>
                <div className="ms-auto">
                    {selectedTrack?.name}
                </div>
                <div className="ms-auto">
                </div>
            </Stack>
            <igc-dockmanager id="dockManager">
                {
                        panesList && panesList.map(pane => {
                            return (<div key={pane.contentId} slot={pane.contentId} className='pane'>{pane.element}</div>);
                        })
                }
            </igc-dockmanager>
        </Stack>
    </>
            ;
}

export default Track;
