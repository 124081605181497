import {useEffect, useState} from "react";
import {useControl} from "react-map-gl";
import {useAppSelector} from "redux/hooks";
import {
    selectHighlightSessionLocation, selectSelectedSession,
} from "redux/features/firestore/sessions/slice";
import {MapboxOverlay, MapboxOverlayProps} from "@deck.gl/mapbox/typed";
import {Layer} from "@deck.gl/core/typed";
import {GeoJsonLayer} from "@deck.gl/layers/typed";
import Session from "redux/features/firestore/sessions/Session";

function DeckGLOverlay(props: MapboxOverlayProps & {
    interleaved?: boolean;
}) {
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
    overlay.setProps(props);
    return null;
}

const HighlightLocationLayer = () => {
    const selectedSession: Session = useAppSelector(selectSelectedSession);
    const highlightSessionLocation: number | null = useAppSelector(selectHighlightSessionLocation);

    const [layer, setLayer] = useState<Layer | undefined>(undefined);

    useEffect(() => {
        if (highlightSessionLocation !== null && selectedSession) {
            const data: any[] = [];
            data.push({
                "type": "Feature",
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                        selectedSession.locations[highlightSessionLocation].longitude,
                        selectedSession.locations[highlightSessionLocation].latitude
                    ]
                },
                "properties": {
                    "color": {
                        r: 255,
                        g: 0,
                        b: 0
                    },
                    "radius": 2,
                }
            });

            const currentLayer = new GeoJsonLayer({
                id: 'Highlight Locations',
                data: data,
                stroked: true,
                getFillColor: (d) => [d.properties!.color.r, d.properties!.color.g, d.properties!.color.b, 100],
                getPointRadius: d => d.properties!.radius,
                getLineColor: (d) => [d.properties!.color.r, d.properties!.color.g, d.properties!.color.b, 255],
                getLineWidth: .5,
            });
            setLayer(currentLayer);
        } else {
            setLayer(undefined);
        }
    }, [highlightSessionLocation, selectedSession])


    return (<DeckGLOverlay layers={[layer]}/>)
}

export default HighlightLocationLayer;
