import AppBar from "./AppBar";
import AllToasts from "redux/features/toasts/AllToasts";
import LoadingModal from "components/LoadingModal";
import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {selectUserLoading} from "redux/features/user/slice";
import {Outlet, Route, Routes} from "react-router-dom";
import Home from "./Home";
import {selectBackendError} from "redux/features/firestore/slice";
import {setToastError} from "redux/features/toasts/slice";
import Track from "./track/Track";
import Session from "./session/Session";
import Account from "./settings/Account";
import Live from "./settings/Live";

const Main = () => {
    const loading = useAppSelector(selectUserLoading);
    const backendError = useAppSelector(selectBackendError);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(!!backendError){
            console.error(backendError);
            dispatch(setToastError(backendError.message));
        }
    }, [backendError, dispatch])

    return <>
        <LoadingModal loading={loading}/>
        <AllToasts/>
        <AppBar/>
        <Outlet />
    </>;
}

export default Main;
