import {PathLayer} from "@deck.gl/layers/typed";
import {Layer} from "@deck.gl/core/typed";
import {useControl} from "react-map-gl";
import {MapboxOverlay, MapboxOverlayProps} from "@deck.gl/mapbox/typed";
import {useAppSelector} from "redux/hooks";
import {
    selectSelectedSession,
    
} from "redux/features/firestore/sessions/slice";
import {useEffect, useState} from "react";
import Session from "redux/features/firestore/sessions/Session";
import Location from "redux/features/firestore/sessions/Location";

function DeckGLOverlay(props: MapboxOverlayProps & {
    interleaved?: boolean;
}) {
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
    overlay.setProps(props);
    return null;
}

const SessionLayer = () => {
    const selectedSession: Session = useAppSelector(selectSelectedSession);

    const [layer, setLayer] = useState<Layer | null>(null);
    useEffect(() => {
        setLayer(new PathLayer({
            id: SessionLayer.name,
            data: [{
                path: selectedSession.locations.map((l: Location) => [l.longitude, l.latitude]),
                color: [255, 0, 0, 40],
            }],
            widthMinPixels: 1,
            getPath: d => d.path,
            getColor: d => d.color,
            getWidth: () => 1
        }));
    }, [selectedSession])

    return <DeckGLOverlay layers={[layer]}/>
}
export default SessionLayer;
