import Lap from "redux/features/firestore/sessions/Lap";
import LapTools from "redux/features/firestore/sessions/LapTools";
import {OverlayTrigger, Popover, Stack} from "react-bootstrap";
import Icon from "react-web-vector-icons";
import React, {useEffect, useState} from "react";
import Session from "redux/features/firestore/sessions/Session";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    hideLap, selectShownLaps,
    setLapHue,
    showLap, ShownLap
} from "redux/features/firestore/sessions/slice";
import Hue from "@uiw/react-color-hue";
import {selectSelectedTrack} from "redux/features/firestore/tracks/slice";

export interface LapTreeItemProps {
    lap: Lap,
    session: Session,
}

const LapTreeItem = ({lap, session}: LapTreeItemProps) => {
    let color = 'green';
    let bestSessionDifference = null;

    if (session.bestLap!.time !== lap.time) {
        color = 'red';
        bestSessionDifference = <div>{LapTools.getFormattedDifferenceWithBest(lap, session)}</div>;
    }

    const shownLaps = useAppSelector(selectShownLaps);
    const [visible, setVisible] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        if (shownLaps) {
            const currentlyVisible = shownLaps.some((l: ShownLap) => LapTools.Equals(l.lap, lap));
            setVisible(currentlyVisible);
        } else {
            setVisible(false);
        }
    }, [lap, shownLaps, session]);

    const selectedTrack = useAppSelector(selectSelectedTrack);
    const dispatch = useAppDispatch();
    const eyeClicked = () => {
        if(selectedTrack) {
            if (visible) {
                dispatch(hideLap({
                    lap: lap,
                    track: selectedTrack,
                }));
            } else {
                dispatch(showLap({
                    lap: lap,
                    track: selectedTrack,
                }));
            }
        }
    };
    const [hue, setHue] = useState(lap.hue * 360);
    const [lapColor, setLapColor] = useState(LapTools.hueToRgb(lap.hue))
    const popover = (
            <Popover id="popover-basic" style={{width: '300px'}}>
                <Popover.Body>
                    <Hue
                            hue={hue}
                            onChange={({h}) => {
                                setHue(h);
                            }}
                    />
                </Popover.Body>
            </Popover>
    );
    useEffect(() => {
        if(selectedTrack) {
            dispatch(setLapHue({
                hue: hue / 360,
                lap: lap,
                track: selectedTrack,
            }));
            const {r, g, b} = LapTools.hueToRgb(hue / 360.0);
            setLapColor({
                r: Math.round(r),
                g: Math.round(g),
                b: Math.round(b)
            });
        }
    }, [dispatch, hue, lap, selectedTrack]);
    return (
            <Stack direction='horizontal' gap={3} style={{width: '100%', color: color}}>
                <div>
                    {lap.number}
                </div>
                <div>
                    {LapTools.getFormattedTime(lap)}
                </div>
                {bestSessionDifference}
                <div className="ms-auto">
                    <Stack direction='horizontal'>
                        {visible &&
                                <div style={{marginRight: '10px'}}>
                                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                        <div>
                                            <Icon font='MaterialCommunityIcons' name='checkbox-blank-circle'
                                                  color={`rgb(${lapColor.r}, ${lapColor.g}, ${lapColor.b})`}
                                                  size={15}/>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                        }
                        <div onClick={eyeClicked}>
                            {visible !== undefined &&
                                    <Icon font='MaterialCommunityIcons' name={visible ? 'eye' : 'eye-off'} color='black'
                                          size={15}/>}
                        </div>
                    </Stack>
                </div>
            </Stack>
    )
}

export default LapTreeItem;
