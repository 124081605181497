import Lap from "./Lap";
import Session from "./Session";
import RealTimeRaceProcessing from "./Processing/RealTimeRaceProcessing";
import Track from "../tracks/Track";
import SessionsProvider from "./SessionsProvider";

module LapTools {
    export function getFormattedTime(payload: Lap): string {
        const minutes = Math.floor(Math.floor(payload.time) / 60);
        const seconds = payload.time - minutes * 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds.toFixed(3)}`;
    }

    export function getDifferenceWithBest(payload: Lap, session: Session): number {
        if (session.bestLap === undefined) {
            return 0;
        }

        return payload.time - session.bestLap.time;
    }

    export function formatTimeDifference(v: number): string {
        return `${v > 0 ? '+' : ''}${v.toFixed(3)}`;
    }

    export function getFormattedDifferenceWithBest(payload: Lap, session: Session): string {
        return formatTimeDifference(getDifferenceWithBest(payload, session));
    }

    export async function process(session: Session) {
        const track: Track = session.track;
        const raceResults = new RealTimeRaceProcessing(track);

        // raceResults.onLog = (m:string) => {
        //     console.log(m);
        // };
        for (const location of session.locations) {
            raceResults.addLocation(location);
        }

        const sessionLaps: Lap[] = [];
        let bestLap: Lap | undefined;

        for (let i = 0; i < raceResults.laps.length; i++) {
            const lap = raceResults.laps[i];
            const sessionLap: Lap = {
                session: session,
                number: i + 1,
                originalNumber: i + 1,
                deleted: i < session.laps.length ? session.laps[i].deleted : false,
                time: lap.timeInSeconds,
                locations: lap.locations,
                hue: i / raceResults.laps.length
            };
            sessionLaps.push(sessionLap);
            if (bestLap === undefined || bestLap?.time > lap.timeInSeconds) {
                bestLap = sessionLap;
            }
        }
        await SessionsProvider.instance.updateSessionLaps(session, sessionLaps);
    }

    export function Equals(l1: Lap, l2: Lap): boolean {
        return l1.session.id === l2.session.id && l1.originalNumber === l2.originalNumber;
    }

    export function hueToRgb(hue: number) {
        const h = hue;
        const s = 1;
        const v = 1;

        let r: number, g: number, b: number;

        const i = Math.floor(h * 6);
        const f = h * 6 - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v;
                g = t;
                b = p;
                break;
            case 1:
                r = q;
                g = v;
                b = p;
                break;
            case 2:
                r = p;
                g = v;
                b = t;
                break;
            case 3:
                r = p;
                g = q;
                b = v;
                break;
            case 4:
                r = t;
                g = p;
                b = v;
                break;
            case 5:
                r = v;
                g = p;
                b = q;
                break;
        }
        return {
            r: Math.round(r! * 255),
            g: Math.round(g! * 255),
            b: Math.round(b! * 255)
        };
    }

    export function hueToHex(hue: number) {
        const {r, g, b} = hueToRgb(hue);
        return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
    }
}

export default LapTools;
