import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {createWhitelistFilter} from "redux-persist-transform-filter";
import User from "../firestore/user/User";
import {isFulfilledAction, isPendingAction, isRejectedAction} from "../helpers";
import AuthProvider from "./AuthProvider";
import {
    EmailAuthProvider,
    createUserWithEmailAndPassword,
    reauthenticateWithCredential,
    sendEmailVerification,
    sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword
} from "firebase/auth";
import UserProvider from "../firestore/user/UserProvider";

export interface UserState {
    initialized: boolean;
    userData: User | undefined;
    resetPasswordEmailSent: boolean;
    verificationEmailSent: boolean;
    loading: boolean;
    error: any | undefined;
}

const sliceName = 'user';

export const persistFilter = createWhitelistFilter(
    sliceName,
    ['userData']
);

const initialState: UserState = {
    initialized: false,
    userData: undefined,
    resetPasswordEmailSent: false,
    verificationEmailSent: false,
    loading: false,
    error: undefined,
};

export interface EmailAndPassword {
    email: string,
    password: string,
}

export interface OldAndNewPasswords {
    oldPassword: string,
    newPassword: string,
}

export const login = createAsyncThunk(
    sliceName + '/login',
    async ({email, password}: EmailAndPassword) => {
        await signInWithEmailAndPassword(AuthProvider.instance.auth, email.trim(), password);
    }
);
export const logout = createAsyncThunk(
    sliceName + '/logout',
    async () => {
        await signOut(AuthProvider.instance.auth);
    }
);
export const register = createAsyncThunk(
    sliceName + '/register',
    async ({email, password}: EmailAndPassword) => {
        await createUserWithEmailAndPassword(AuthProvider.instance.auth, email.trim(), password);
    }
);
const reauthenticate = async (password: string) => {
    const credential = EmailAuthProvider.credential(AuthProvider.instance.auth.currentUser!.email!, password);
    await reauthenticateWithCredential(AuthProvider.instance.auth.currentUser!, credential);
};
export const changeEmail = createAsyncThunk(
    sliceName + '/changeEmail',
    async ({email, password}: EmailAndPassword) => {
        await reauthenticate(password);
        await updateEmail(AuthProvider.instance.auth.currentUser!, email);
        await signOut(AuthProvider.instance.auth);
    }
);
export const changePassword = createAsyncThunk(
    sliceName + '/changePassword',
    async ({oldPassword, newPassword}: OldAndNewPasswords) => {
        await reauthenticate(oldPassword);
        await updatePassword(AuthProvider.instance.auth.currentUser!, newPassword);
        await signOut(AuthProvider.instance.auth);
    }
);
export const resetPasswordEmail = createAsyncThunk(
    sliceName + '/resetPasswordEmail',
    async (email: string) => {
        await sendPasswordResetEmail(AuthProvider.instance.auth, email.trim());
    }
);
export const sendVerificationEmail = createAsyncThunk(
    sliceName + '/sendVerificationEmail',
    async () => {
        await sendEmailVerification(AuthProvider.instance.auth.currentUser!);
    }
);
export const reloadUser = createAsyncThunk(
    sliceName + '/reloadUser',
    async () => {
        await AuthProvider.instance.auth.currentUser!.reload();
        return await UserProvider.instance.getUser(AuthProvider.instance.auth.currentUser!);
    }
);
export const deleteUser = createAsyncThunk(
    sliceName + '/deleteUser',
    async (password: string) => {
        await reauthenticate(password);
        await AuthProvider.instance.auth.currentUser!.delete();
    }
);

export const setYoutubeKey = createAsyncThunk(
    sliceName + '/setYoutubeKey',
    async (key: string | null) => {
        await UserProvider.instance.setYoutubeKey(key);
        return key;
    }
);


export const userSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        loggedOut: (state) => {
            state.userData = undefined;
            state.initialized = true;
        },
        loggedIn: (state, action: PayloadAction<User>) => {
            state.userData = action.payload;
            state.initialized = true;
        },
        clearError: (state) => {
            state.loading = false;
            state.error = undefined;
        },
        clearResetPasswordEmailSent: (state) => {
            state.resetPasswordEmailSent = false;
        },
        clearVerificationEmailSent: (state) => {
            state.verificationEmailSent = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeEmail.fulfilled, (state) => {
                state.userData = undefined;
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.userData = undefined;
            })
            .addCase(resetPasswordEmail.pending, (state) => {
                state.loading = true;
                state.resetPasswordEmailSent = false;
                state.error = undefined;
            })
            .addCase(resetPasswordEmail.fulfilled, (state) => {
                state.loading = false;
                state.resetPasswordEmailSent = true;
                state.error = undefined;
            })
            .addCase(sendVerificationEmail.pending, (state) => {
                state.loading = true;
                state.verificationEmailSent = false;
                state.error = undefined;
            })
            .addCase(sendVerificationEmail.fulfilled, (state) => {
                state.loading = false;
                state.verificationEmailSent = true;
                state.error = undefined;
            })
            .addCase(reloadUser.fulfilled, (state, action) => {
                state.userData = action.payload;
            })
            .addCase(deleteUser.fulfilled, (state) => {
                state.userData = undefined;
            })
            .addCase(setYoutubeKey.fulfilled, (state, action) => {
                state.userData = {
                    ...state.userData!,
                    youtubeKey: action.payload
                };
            })
            .addMatcher(isPendingAction(sliceName), (state) => {
                state.loading = true;
                state.error = undefined;
            })
            .addMatcher(isFulfilledAction(sliceName), (state) => {
                state.loading = false;
                state.error = undefined;
            })
            .addMatcher(isRejectedAction(sliceName), (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
        ;
    },
});

export const {
    loggedOut,
    loggedIn,
    clearError,
    clearResetPasswordEmailSent,
    clearVerificationEmailSent,
} = userSlice.actions;

export const selectInitialized = (state: RootState) => state.user.initialized;

export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUserError = (state: RootState) => state.user.error;

export const selectUser = (state: RootState) => state.user.userData;

export const selectResetPasswordEmailSent = (state: RootState) => state.user.resetPasswordEmailSent;
export const selectVerificationEmailSent = (state: RootState) => state.user.verificationEmailSent;

export default userSlice.reducer;
