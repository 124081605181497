import Session from "./Session";

module SessionTools {
    export function getNbLaps(payload: Session): number {
        let count = 0;
        for (const lap of payload.laps) {
            if (!lap.deleted) {
                count++;
            }
        }
        return count;
    }
    
    export function getTimestampUTC(session: Session): Date {
        return new Date(session.timestampUTCSeconds * 1000);
    }

    export function deleteLap(payload: Session, lapIndex: number) {
        if (!payload.laps[lapIndex].deleted) {
            payload.laps[lapIndex].deleted = true;
            recalculateLapsNumbersAndFastestLap(payload);
        }
    }

    export function restoreLap(payload: Session, lapIndex: number) {
        if (payload.laps[lapIndex].deleted) {
            payload.laps[lapIndex].deleted = false;
            recalculateLapsNumbersAndFastestLap(payload);
        }
    }

    function recalculateLapsNumbersAndFastestLap(payload: Session) {
        payload.bestLap = undefined;
        let lastLap = 1;
        for (const lap of payload.laps) {
            if (!lap.deleted) {
                lap.number = lastLap;
                lastLap++;
                if (payload.bestLap === undefined || payload.bestLap.time > lap.time) {
                    payload.bestLap = lap;
                }
            } else {
                lap.number = null;
            }
        }
    }
}

export default SessionTools;