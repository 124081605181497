import {
    IgcContentPane
} from "igniteui-dockmanager/dist/types/components/dockmanager/dockmanager.public-interfaces";
import {IgcDockManagerPaneType} from "igniteui-dockmanager";

export default class DockPane {
    public readonly contentId: string;
    private readonly header: string;
    public readonly element: JSX.Element;
    
    constructor(contentId: string, header: string, element: JSX.Element) {
        this.contentId = contentId;
        this.element = element;
        this.header = header;
    }
    
    get pane(): IgcContentPane {
        return {
            type: IgcDockManagerPaneType.contentPane,
            header: this.header,
            contentId: this.contentId,
            allowClose: false,
        }
    }
}