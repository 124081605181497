import React, {useEffect, useRef, useState} from "react";
import Lap from "redux/features/firestore/sessions/Lap";
import LapTools from "redux/features/firestore/sessions/LapTools";
import {useAppSelector} from "redux/hooks";
import {
    selectFastestShownLap, selectHighlightLocation, selectHighlightRange,
    selectShownLaps, ShownLap,
} from "redux/features/firestore/sessions/slice";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {Line} from "react-chartjs-2";
import {ActiveDataPoint, ChartData, ChartDataset, ChartOptions, TooltipItem} from "chart.js/dist/types";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        annotationPlugin
);

const Graph = (
        getData: (index: number, fastestLap: Lap, shownLaps: ShownLap) => number,
        formatValue: (value: number) => string,
        unit: string,
        showFastest: boolean,
        leftPadding: number = 0,
        highlightZero: boolean = false) => {
    const fastestShownLap: Lap | null = useAppSelector(selectFastestShownLap);
    const shownLaps: ShownLap[] | null = useAppSelector(selectShownLaps);

    const [fullData, setFullData] = useState<ChartData<'line'> | null>(null);
    useEffect(() => {
        if (fastestShownLap && shownLaps) {
            const currentData: ChartData<'line'> = {
                labels: Array.from(Array(fastestShownLap.locations!.length).keys()),
                datasets: [],
            };

            for (const shownLap of shownLaps) {
                if (!showFastest && LapTools.Equals(shownLap.lap, fastestShownLap)) {
                    continue;
                }
                const color = LapTools.hueToRgb(shownLap.lap.hue);

                const dataSet: ChartDataset<'line', number[]> = {
                    label: `lap ${shownLap.lap.number}`,
                    data: [],
                    borderColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
                    backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.5)`,
                    pointStyle: false,
                    borderWidth: (LapTools.Equals(fastestShownLap, shownLap.lap)) ? 6 : 3,
                };

                for (let i = 0; i < Math.min(fastestShownLap.locations!.length, shownLap.calculatedLocations!.length) ; i++) {
                    dataSet.data.push(getData(i, fastestShownLap, shownLap));
                }
                currentData.datasets.push(dataSet);
            }
            setFullData(currentData);
        } else {
            setFullData(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shownLaps, fastestShownLap, showFastest]);

    const chartRef = useRef<ChartJS>(null);
    const highlightLocation: number | null = useAppSelector(selectHighlightLocation);
    const [data, setData] = useState<ChartData<'line'> | null>(null);
    const highlightRange: [number, number] | null = useAppSelector(selectHighlightRange);
    useEffect(() => {
        if (highlightRange && fullData) {
            setData({
                ...fullData,
                labels: fullData.labels!.slice(highlightRange[0], highlightRange[1] + 1),
                datasets: fullData.datasets.map(dataset => {
                    return {
                        ...dataset,
                        data: dataset.data.slice(highlightRange[0], highlightRange[1] + 1),
                    };
                }),
            });
        } else {
            setData(null);
        }
    }, [highlightRange, fullData])
    const [highlightLocationInRange, setHighlightLocationInRange] = useState<number | null>(null);
    useEffect(() => {
        if (highlightLocation) {
            if (highlightRange) {
                setHighlightLocationInRange(
                        Math.max(0,
                                Math.min(highlightRange[1] - highlightRange[0],
                                        highlightLocation - highlightRange[0])));
            } else {
                setHighlightLocationInRange(highlightLocation);
            }
        } else {
            setHighlightLocationInRange(null);
        }
    }, [highlightLocation, highlightRange]);

    useEffect(() => {
        if (chartRef.current && highlightLocationInRange && fullData) {
            const tooltip = chartRef.current?.tooltip;

            if (!tooltip) {
                return;
            }

            if (tooltip.getActiveElements().length > 0) {
                tooltip.setActiveElements([], {x: 0, y: 0});
            }
            const {chartArea} = chartRef.current;
            const active: ActiveDataPoint[] = []
            for (let i = 0; i < fullData.datasets.length; i++) {
                active.push({
                    datasetIndex: i,
                    index: highlightLocationInRange,
                });
            }
            tooltip.setActiveElements(
                    active,
                    {
                        x: (chartArea.left + chartArea.right) / 2,
                        y: (chartArea.top + chartArea.bottom) / 2,
                    }
            );

            chartRef.current.update();
        }
    }, [highlightLocationInRange, chartRef, fullData]);

    if (!data) {
        return <></>;
    }

    const options: ChartOptions<'line'> = {
        maintainAspectRatio: false,
        responsive: true,
        events: [],
        layout: {
            padding: {
                left: leftPadding,
                right: 20,
            }
        },
        animation: {
            duration: 0,
        },
        scales: {
            'y': {
                ticks: {
                    callback: tickValue => {
                        return `${tickValue} ${unit}`;
                    }
                }
            },
            'x': {
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title(): string {
                        return '';
                    },
                    label(tooltipItem: TooltipItem<'line'>): string {
                        return `\t${formatValue(tooltipItem.raw as number)} ${unit}`;
                    },
                }
            },
            annotation: {
                annotations: {
                    highlightLine: {
                        display: highlightLocationInRange != null,
                        type: 'line',
                        xMin: highlightLocationInRange ? highlightLocationInRange : 0,
                        xMax: highlightLocationInRange ? highlightLocationInRange : 0,
                        borderColor: 'rgba(0, 0, 0, 0.8)',
                        borderWidth: 3,
                    },
                    highlightZero: {
                        display: highlightZero,
                        type: 'line',
                        yMin: 0,
                        yMax: 0,
                        borderColor: 'rgba(0, 0, 0, 0.8)',
                        borderWidth: 2,
                    }
                }
            }
        },
    };

    return (
            <Line ref={chartRef} height='100%' width='100%' options={options} data={data}/>
    );
}
export default Graph;
