import React, {forwardRef} from "react";
import {
    useTreeItem,
    TreeItemContentProps,
} from "@mui/lab/TreeItem";
import clsx from "clsx";

const SessionsListTreeItem = forwardRef(function CustomContent(
        {
            classes,
            className,
            label,
            nodeId,
            icon: iconProp,
            expansionIcon,
            displayIcon,
        }: TreeItemContentProps,
        ref,
) {
    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        preventSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        preventSelection(event);
    };

    const handleExpansionClick = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        handleExpansion(event);
    };

    return (
            <div
                    className={clsx(className, classes.root, {
                        [classes.expanded]: expanded,
                        [classes.selected]: selected,
                        [classes.focused]: focused,
                        [classes.disabled]: disabled,
                    })}
                    onMouseDown={handleMouseDown}
                    ref={ref as React.Ref<HTMLDivElement>}
                    style={{paddingTop: '8px', paddingBottom: '8px'}}
            >
                <div onClick={handleExpansionClick} className={classes.iconContainer}>
                    {icon}
                </div>
                {label}
            </div>
    );
});

export default SessionsListTreeItem;
