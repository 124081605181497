import {combineReducers} from "redux";
import userReducer from "./features/user/slice";
import toastsReducer from "./features/toasts/slice";
import firestoreReducer from "./features/firestore/slice";
import tracksReducer from "./features/firestore/tracks/slice";
import sessionsReducer from "./features/firestore/sessions/slice";

const rootReducer = combineReducers({
    user: userReducer,
    toasts: toastsReducer,
    firestore: firestoreReducer,
    tracks: tracksReducer,
    sessions: sessionsReducer,
});

export default rootReducer;
