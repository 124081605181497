import {doc, Firestore, getDoc, getFirestore, updateDoc} from "firebase/firestore";
import firebaseBackend from "../../../../backend/firebaseBackend";
import {User as FirebaseUser} from "@firebase/auth";
import AuthProvider from "../../user/AuthProvider";
import User from "./User";

export default class UserProvider {
    private static _instance: UserProvider;
    public static get instance(): UserProvider {
        if (!UserProvider._instance) {
            UserProvider._instance = new UserProvider();
        }

        return UserProvider._instance;
    }

    private constructor() {
    }

    _firestore?: Firestore;
    get firestore(): Firestore {
        if (!this._firestore) {
            this._firestore = getFirestore(firebaseBackend);
        }
        return this._firestore;
    }

    async getUser(user: FirebaseUser): Promise<User> {
        const docRef = doc(
            this.firestore,
            'users', user.uid);
        const docSnap = await getDoc(docRef);
        return {
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            allowLive: false,
            youtubeKey: null,
            ...docSnap.data()
        };
    }

    async setYoutubeKey(key: string | null) {
        const firestore = getFirestore(firebaseBackend);
        const docRef = doc(firestore,
            'users', AuthProvider.instance.auth.currentUser!.uid);
        await updateDoc(docRef, {
            youtubeKey: key
        })
    }
}
