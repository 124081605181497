import Session from "redux/features/firestore/sessions/Session";
import moment from "moment";
import SessionTools from "redux/features/firestore/sessions/SessionTools";
import LapTools from "redux/features/firestore/sessions/LapTools";
import {Stack} from "react-bootstrap";
import Icon from "react-web-vector-icons";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    hideLap,
    selectShownLaps,
    showLap,
    ShownLap
} from "redux/features/firestore/sessions/slice";
import {selectSelectedTrack} from "redux/features/firestore/tracks/slice";

export interface SessionTreeItemProps {
    session: Session,
    bestSession?: Session,
}

const SessionTreeItem = ({session, bestSession}: SessionTreeItemProps) => {
    let color = 'black';
    let bestSessionDifference = null;

    if (bestSession && session.bestLap) {
        if (session.bestLap.time === bestSession.bestLap!.time) {
            color = 'green';
        } else {
            color = 'red';
            bestSessionDifference = <div>{LapTools.getFormattedDifferenceWithBest(session.bestLap, bestSession)}</div>;
        }
    }

    const shownLaps : ShownLap[] | null = useAppSelector(selectShownLaps);

    enum Visible {
        Visible,
        Hidden,
        Mixed
    }

    const [visible, setVisible] = useState<Visible | undefined>(undefined);
    useEffect(() => {
        if (!shownLaps) {
            setVisible(Visible.Hidden);
        } else {
            let notDeletedLaps = 0;
            for (const lap of session.laps) {
                if (!lap.deleted) {
                    notDeletedLaps++;
                }
            }
            let shownLapsForSession = 0;
            for (const otherShownLap of shownLaps) {
                if(otherShownLap.lap.session.id === session.id) {
                    shownLapsForSession++;
                }
            }
            let currentlyVisible: Visible;
            if (shownLapsForSession === notDeletedLaps) {
                currentlyVisible = Visible.Visible;
            } else if (shownLapsForSession === 0) {
                currentlyVisible = Visible.Hidden;
            } else {
                currentlyVisible = Visible.Mixed;
            }
            setVisible(currentlyVisible);
        }
    }, [session, shownLaps, Visible.Hidden, Visible.Visible, Visible.Mixed]);

    const selectedTrack = useAppSelector(selectSelectedTrack);
    const dispatch = useAppDispatch();
    const clicked = () => {
        if (selectedTrack && visible !== undefined) {
            for (const lap of session.laps) {
                if (!lap.deleted) {
                    if (visible === Visible.Visible) {
                        dispatch(hideLap({
                            lap: lap,
                            track: selectedTrack,
                        }));
                    } else {
                        dispatch(showLap({
                            lap: lap,
                            track: selectedTrack,
                        }));
                    }
                }
            }
        }
    };
    return (
            <Stack direction='horizontal' gap={3} style={{width: '100%', color: color}}>
                {/*<div>*/}
                {/*    {session.id}*/}
                {/*</div>*/}
                <div>
                    {moment(SessionTools.getTimestampUTC(session)).format('yyyy-MM-DD')}
                </div>
                <div>
                    {moment(SessionTools.getTimestampUTC(session)).format('HH:mm')}
                </div>
                <div>
                    {session.bestLap && LapTools.getFormattedTime(session.bestLap!)}
                </div>
                {bestSessionDifference}
                <div className="ms-auto">
                    <Typography onClick={clicked}
                                component="div">
                        {visible !== undefined && <Icon font='MaterialCommunityIcons'
                                                        name={visible === Visible.Visible ? 'eye' : visible === Visible.Hidden ? 'eye-off' : 'eye-off-outline'}
                                                        color='black' size={15}/>}
                    </Typography>
                </div>
            </Stack>
    )
}

export default SessionTreeItem;
