import {createWhitelistFilter} from "redux-persist-transform-filter";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

export interface ToastsState {
    error?: string;
}

const sliceName = 'toasts';

export const persistFilter = createWhitelistFilter(
    sliceName,
    []
);

const initialState: ToastsState = {
};

export const toastsSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        setToastError: (state, action: PayloadAction<string|undefined>) => {
            state.error = action.payload;
        },
    },
});

export const {
    setToastError,
} = toastsSlice.actions;

export const selectError = (state: RootState) => state.toasts.error;

export default toastsSlice.reducer;
