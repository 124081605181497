import Graph from "./Graph";
import Lap from "redux/features/firestore/sessions/Lap";
import {ShownLap} from "redux/features/firestore/sessions/slice";

const SpeedGraph = () => {
    return Graph(
            (index: number, fastestLap: Lap, shownLap: ShownLap): number => shownLap.calculatedLocations![index].location.speedKpH,
            (value: number) => `${value.toFixed(2)}`,
            'km/h',
            true);
}
export default SpeedGraph;
