import {Form, Modal} from "react-bootstrap";
import {Formik, FormikProps, FormikValues} from "formik";
import React from "react";
import styles from "./QuestionPopup.module.scss";

export interface IQuestionPopupProps {
    title?: string;
    schema?: any;
    initialValues?: FormikValues;
    body?: (formikProps: FormikProps<any>) => JSX.Element | string;
    onSubmit?: any;
    open: boolean;
    onClose: () => any;
    footerButtons: (formikProps: FormikProps<any>) => JSX.Element;
    footerError?: string;
}

const QuestionPopup = ({
                           title,
                           schema,
                           initialValues,
                           body,
                           onSubmit,
                           footerButtons,
                           open,
                           onClose,
                           footerError,
                       }: IQuestionPopupProps) => {
    return (
            <Formik
                    validationSchema={schema}
                    initialValues={!!initialValues ? initialValues : {}}
                    onSubmit={onSubmit}
            >
                {(formikProps) => (
                        <Modal show={open} onHide={() => {
                            formikProps.resetForm();
                            onClose()
                        }} centered>
                            <Form noValidate onSubmit={formikProps.handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {body && body(formikProps)}
                                </Modal.Body>
                                <Modal.Footer>
                                    {footerButtons(formikProps)}
                                </Modal.Footer>
                                <div className={styles.footerError}>
                                    <p>{footerError}</p>
                                </div>
                            </Form>
                        </Modal>
                )}
            </Formik>
    );
}

export default QuestionPopup;
