import {
    collection,
    Firestore, getDocs,
    getFirestore,
    limit,
    query,
    QueryConstraint,
    startAfter,
    DocumentSnapshot,
    DocumentData, orderBy, doc, getDoc
} from "firebase/firestore";
import firebaseBackend from "../../../../backend/firebaseBackend";
import {store} from "../../../store";
import Track from "./Track";

export default class TracksProvider {
    private static _instance: TracksProvider;
    public static get instance(): TracksProvider {
        if (!TracksProvider._instance) {
            TracksProvider._instance = new TracksProvider();
        }

        return TracksProvider._instance;
    }

    private constructor() {
    }

    _firestore?: Firestore;
    get firestore(): Firestore {
        if(!this._firestore) {
            this._firestore = getFirestore(firebaseBackend);
        }
        return this._firestore;
    }

    private getTrackFromDocSnapshot(doc: DocumentSnapshot) {
        const docData = doc.data()!;
        const track: Track = {
            id: doc.id,
            name: docData.name,
            lastSessionTimestampUTCSeconds: docData.lastSessionTimestampUTCSeconds.seconds,
            svgViewBox: docData.svgViewBox,
            svgPolygonPositions: docData.svgPolygonPositions,
            startLocation: {
                latitude: docData.startLocation.latitude,
                longitude: docData.startLocation.longitude,
            },
            startRotation: docData.startRotation,
        };
        return track;
    }

    lastTrackByMostRecentSession: DocumentData | undefined = undefined;
    async getTracksByMostRecentSession(nb: number) {
        const storeState = store.getState();

        const constraints: QueryConstraint[] = [
            orderBy("lastSessionTimestampUTCSeconds","desc"),
            limit(nb),
        ];
        if(!!this.lastTrackByMostRecentSession) {
            constraints.push(startAfter(this.lastTrackByMostRecentSession));
        }
        const q = query(
            collection(this.firestore, `users/${storeState.user.userData.uid}/tracks`),
            ...constraints
        );
        const snapshot = await getDocs(q);

        const ret: Track[] = [];
        for (const doc of snapshot.docs) {
            const track = this.getTrackFromDocSnapshot(doc);
            ret.push(track);
            this.lastTrackByMostRecentSession = doc;
        }

        return ret;
    }

    async getTrackById(id: string) {
        const storeState = store.getState();

        const docRef = doc(this.firestore,
            `users/${storeState.user.userData.uid}/tracks`, id);
        const docSnap = await getDoc(docRef);
        if(!docSnap.exists()) {
            return undefined;
        } else {
            return this.getTrackFromDocSnapshot(docSnap);
        }
    }
}
