import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createWhitelistFilter} from "redux-persist-transform-filter";
import {sliceName as parentSliceMame} from "../slice";
import TracksProvider from "./TracksProvider";
import Track from "./Track";
import {RootState} from "../../../store";

export interface TracksState {
    tracksByMostRecentSession: Track[] | undefined;
    tracksByMostRecentSessionHasMoreItems: boolean;
    selectedTrack: Track | undefined;
}

const initialState: TracksState = {
    tracksByMostRecentSession: undefined,
    tracksByMostRecentSessionHasMoreItems: true,
    selectedTrack: undefined,
};

export const sliceName = 'tracks';
export const persistFilter = createWhitelistFilter(
    sliceName,
    []
);

const pageItemsCount = 20;
export const getTracksByMostRecentSession = createAsyncThunk(
    `${parentSliceMame}/${sliceName}/getTracksByMostRecentSession`,
    async () => {
        return await TracksProvider.instance.getTracksByMostRecentSession(pageItemsCount);
    }
);

export const selectTrackById = createAsyncThunk(
    `${parentSliceMame}/${sliceName}/getTrackById`,
    async (id: string) => {
        return await TracksProvider.instance.getTrackById(id);
    }
);

export const tracksSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        "clearSelectedTrack": (state) => {
            state.selectedTrack = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTracksByMostRecentSession.fulfilled, (state, action) => {
                if(!state.tracksByMostRecentSession) {
                    state.tracksByMostRecentSession = action.payload;
                } else {
                    const existing = new Set(state.tracksByMostRecentSession.map(item => item.id));
                    for (const payloadElement of action.payload) {
                        if(!existing.has(payloadElement.id)) {
                            state.tracksByMostRecentSession.push(payloadElement);
                        }
                    }
                }
                state.tracksByMostRecentSessionHasMoreItems = action.payload.length === pageItemsCount;
            })
            .addCase(selectTrackById.fulfilled, (state, action) => {
                state.selectedTrack = action.payload;
            })
    },
});

export const {clearSelectedTrack} = tracksSlice.actions;

export const selectTracksByMostRecentSession = (state: RootState) => state.tracks.tracksByMostRecentSession;
export const selectTracksByMostRecentSessionHasMoreItems = (state: RootState) => state.tracks.tracksByMostRecentSessionHasMoreItems;
export const selectSelectedTrack = (state: RootState) => state.tracks.selectedTrack;

export default tracksSlice.reducer;
