import React, {useEffect, useState} from "react";
import {Button, Form, Stack, Row, Col, Modal} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {Formik, FormikValues} from "formik";
import * as yup from "yup";
import ValidatedFormControl from "components/ValidatedFormControl";
import ErrorModal from "components/ErrorModal";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    clearResetPasswordEmailSent,
    resetPasswordEmail,
    selectResetPasswordEmailSent,
    selectUserError,
    selectUserLoading
} from "redux/features/user/slice";
import LoadingModal from "components/LoadingModal";

const schema = yup.object().shape({
    email: yup.string().required().email(),
});

const ForgotPassword = () => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectUserLoading);
    const userError = useAppSelector(selectUserError);

    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if(!userError) {
            setError('');
        } else if (userError.code === 'auth/invalid-email') {
            setError('Invalid email');
        } else if (userError.code === 'auth/user-not-found') {
            setError('Email not found');
        } else {
            setError(userError.message);
        }
    }, [userError])


    const doResetPassword = async (values: FormikValues, actions: any) => {
        dispatch(resetPasswordEmail(values.email))
        actions.setSubmitting(false);
    };

    const navigate = useNavigate();
    const closeEmailSent = () => {
        dispatch(clearResetPasswordEmailSent());
        navigate(-1);
    };

    const resetPasswordEmailSent = useAppSelector(selectResetPasswordEmailSent)
    return (
            <>
                <ErrorModal error={error} setError={setError}/>
                <Modal show={resetPasswordEmailSent} onHide={closeEmailSent}>
                    <Modal.Header closeButton>
                        <Modal.Title>Email sent</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        We sent you an email with a link to reset your password. If you didn't receive it make sure to
                        check your spam folder.
                    </Modal.Body>
                </Modal>
                <LoadingModal loading={loading} />
                <Formik
                        validationSchema={schema}
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={doResetPassword}>
                    {(formikProps) => (
                            <Form noValidate onSubmit={formikProps.handleSubmit}>
                                <Row>
                                    <Col/>
                                    <Col xs={3}>
                                        <Stack gap={1}>
                                            <div style={{height: '30px'}}></div>
                                            <div style={{paddingLeft: '20px'}}>
                                                <h1>Reset your password</h1>
                                                <p>We will send you an email to reset your password</p>
                                            </div>
                                            <div style={{height: '30px'}}></div>
                                            <ValidatedFormControl label='Email address' type='email'
                                                                  placeholder='Enter your email address'
                                                                  formikProps={formikProps} field='email'/>
                                            <Form.Group className="mb-3">
                                                <div className="d-grid gap-2">
                                                    <Button variant="primary" type="submit" size="lg"
                                                            disabled={formikProps.isSubmitting}>
                                                        {formikProps.isSubmitting ? 'Resetting your password' : 'Reset my password'}
                                                    </Button>
                                                </div>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                    <Col/>
                                </Row>
                                <Row>
                                    <Col/>
                                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                                        <Link to='..'>Go Back</Link>
                                    </Col>
                                    <Col/>
                                </Row>
                            </Form>
                    )}
                </Formik>
            </>
    );
}

export default ForgotPassword;
