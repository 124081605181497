import styles from "./Account.module.scss";
import QuestionPopup from "components/QuestionPopup";
import {Button} from "react-bootstrap";
import {changeEmail, changePassword, deleteUser, logout, selectUserError} from "redux/features/user/slice";
import ValidatedFormControl from "components/ValidatedFormControl";
import {FormikValues} from "formik";
import React, {useEffect, useState} from "react";
import {setToastError} from "redux/features/toasts/slice";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import * as yup from "yup";
import {Stack} from "@mui/material";

const deleteSchema = yup.object().shape({
    password: yup.string().required(),
});
const changeEmailSchema = yup.object().shape({
    password: yup.string().required(),
    newEmail: yup.string().required('New email is required'),
    repeatEmail: yup.string().oneOf([yup.ref('newEmail'), null], 'Emails don\'t match').required('Please repeat your email'),
});
const changePasswordSchema = yup.object().shape({
    password: yup.string().required(),
    newPassword: yup.string().required('New password is required'),
    repeatPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords don\'t match').required('Please repeat your new password'),
});

const Account = () => {
    const dispatch = useAppDispatch();
    const backendError = useAppSelector(selectUserError);
    const [error, setError] = useState('');
    useEffect(() => {
        if (backendError === undefined) {
            setError('');
        } else if (backendError.code === 'auth/wrong-password') {
            setError('Wrong password');
        } else if (backendError.code === 'auth/invalid-email') {
            setError('Invalid email')
        } else if (backendError.code === 'auth/weak-password') {
            setError('Your password is not safe, make it at least 6 characters long');
        } else {
            setError(backendError.message);
        }
    }, [backendError, dispatch])

    const [openModal, setOpenModal] = useState<'logout' | 'delete' | 'changeEmail' | 'changePassword' | null>(null);
    const closeModal = () => {
        setOpenModal(null);
        dispatch(setToastError(undefined))
    };
    useEffect(() => {
        setError('');
    }, [openModal]);

    return <>
        <QuestionPopup
            title="Logout?"
            body={() => "Your data will remain safe on our server and you will be able to sign back in whenever you want."}
            open={openModal === 'logout'}
            onClose={closeModal}
            footerButtons={() => (
                <>
                    <Button
                        variant="warning"
                        onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => dispatch(logout())}>
                        Logout
                    </Button>,
                </>)}
        />
        <QuestionPopup
            title="Delete account?"
            schema={deleteSchema}
            initialValues={{
                password: ''
            }}
            body={(formikProps) => (
                <>
                    <p>
                        If you delete your account this cannot be reversed, all your data
                        will be lost.
                    </p>
                    <ValidatedFormControl label='Password' type='password'
                                          placeholder='Enter your password'
                                          formikProps={formikProps} field='password'/>
                </>
            )}
            open={openModal === 'delete'}
            onClose={closeModal}
            onSubmit={(values: FormikValues, actions: any) => {
                setError('');
                dispatch(deleteUser(values.password));
                actions.setSubmitting(false);
            }}
            footerButtons={(formikProps) => (<>
                <Button
                    disabled={formikProps.isSubmitting}
                    variant="warning"
                    onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    disabled={formikProps.isSubmitting}
                    variant="success"
                    onClick={() => dispatch(logout())}>
                    Logout
                </Button>
                <Button
                    disabled={formikProps.isSubmitting}
                    type="submit"
                    variant="danger">
                    Delete
                </Button>
            </>)}
            footerError={error}
        />
        <QuestionPopup
            title="Change email?"
            schema={changeEmailSchema}
            initialValues={{
                password: '',
                newEmail: '',
                repeatEmail: '',
            }}
            body={(formikProps) => (
                <>
                    <ValidatedFormControl label='Password' type='password'
                                          placeholder='Enter your password'
                                          formikProps={formikProps} field='password'/>
                    <ValidatedFormControl label='New email' type='email'
                                          placeholder='Enter your new email'
                                          formikProps={formikProps} field='newEmail'/>
                    <ValidatedFormControl label='Repeat email' type='email'
                                          placeholder='Repeat your new email'
                                          formikProps={formikProps} field='repeatEmail'/>
                </>
            )}
            open={openModal === 'changeEmail'}
            onClose={closeModal}
            onSubmit={(values: FormikValues, actions: any) => {
                setError('');
                dispatch(changeEmail({
                    email: values.newEmail,
                    password: values.password
                }));
                actions.setSubmitting(false);
            }}
            footerButtons={(formikProps) => (<>
                <Button
                    disabled={formikProps.isSubmitting}
                    variant="warning"
                    onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    disabled={formikProps.isSubmitting}
                    type="submit"
                    variant="danger">
                    Change my email
                </Button>
            </>)}
            footerError={error}
        />
        <QuestionPopup
            title="Change password?"
            schema={changePasswordSchema}
            initialValues={{
                password: '',
                newPassword: '',
                repeatPassword: '',
            }}
            body={(formikProps) => (
                <>
                    <ValidatedFormControl label='Password' type='password'
                                          placeholder='Enter your password'
                                          formikProps={formikProps} field='password'/>
                    <ValidatedFormControl label='New password' type='password'
                                          placeholder='Enter your new password'
                                          formikProps={formikProps} field='newPassword'/>
                    <ValidatedFormControl label='Repeat password' type='password'
                                          placeholder='Repeat your new password'
                                          formikProps={formikProps} field='repeatPassword'/>
                </>
            )}
            open={openModal === 'changePassword'}
            onClose={closeModal}
            onSubmit={(values: FormikValues, actions: any) => {
                setError('');
                dispatch(changePassword({
                    oldPassword: values.password,
                    newPassword: values.newPassword,
                }));
                actions.setSubmitting(false);
            }}
            footerButtons={(formikProps) => (<>
                <Button
                    disabled={formikProps.isSubmitting}
                    variant="warning"
                    onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    disabled={formikProps.isSubmitting}
                    type="submit"
                    variant="danger">
                    Change my password
                </Button>
            </>)}
            footerError={error}
        />
        <Stack spacing={3}
               margin='auto'
               paddingX={6}
               paddingY={4}
               maxWidth={270}>
            <Button onClick={() => setOpenModal('changeEmail')}>Change
                email</Button>
            <Button onClick={() => setOpenModal('changePassword')}>Change
                password</Button>
            <Button onClick={() => setOpenModal('logout')}
                    className={styles.dangerItem}>
                Logout
            </Button>
            <Button onClick={() => setOpenModal('delete')}
                    className={styles.dangerItem}>
                Delete account
            </Button>
        </Stack>
    </>;
}

export default Account;
