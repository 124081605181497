import Track from "redux/features/firestore/tracks/Track";
import {Button, Card} from "react-bootstrap";
import moment from "moment";
import TrackTools from "redux/features/firestore/tracks/TrackTools";
import {useNavigate} from "react-router-dom";

export interface TrackCellProps {
    key: number;
    track: Track;
}

const TrackCell = ({track}: TrackCellProps) => {
    const navigate = useNavigate();

    const image = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${track.svgViewBox}"><polygon points="${track.svgPolygonPositions}" fill="transparent" stroke-width="8" stroke="black" stroke-opacity="0.2"></polygon></svg>`;
    return (
            <Button variant="outline-primary" onClick={() => {
                navigate(`/members/${track.id}`);
            }}>
                <Card className="text-center">
                    <Card.Img src={`data:image/svg+xml;utf8,${image}`} style={{maxHeight: '300px'}}/>
                    <Card.ImgOverlay>
                        <Card.Body>
                            <Card.Title>{track.name}</Card.Title>
                        </Card.Body>
                    </Card.ImgOverlay>
                    <Card.Footer>
                        <small className="text-muted">Last raced here {moment(TrackTools.getLastSessionTimestamp(track)).fromNow()}</small>
                    </Card.Footer>
                </Card>
            </Button>
    );
}

export default TrackCell;
