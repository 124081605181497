import React, {useEffect, useState} from "react";
import Slider from "@mui/material/Slider";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {selectFastestShownLap, setHighlightLocation, setHighlightRange} from "redux/features/firestore/sessions/slice";
import Lap from "redux/features/firestore/sessions/Lap";
import {Stack} from "react-bootstrap";

const Controls = () => {
    const [position, setPosition] = useState<number>(0);
    const [range, setRange] = useState<[number, number]>([0,0]);
    const [max, setMax] = useState<number>(0);

    const fastestShownLap: Lap = useAppSelector(selectFastestShownLap);

    useEffect(() => {
        if (fastestShownLap) {
            const max = fastestShownLap.locations!.length - 1;
            setMax(max);
            setRange([0, max])
        } else {
            setMax(0);
            setRange([0, 0])
        }
    }, [fastestShownLap]);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const newPosition = Math.max(0, range[0], Math.min(max, range[1], position));
        setPosition(newPosition);
        dispatch(setHighlightRange(range));
        dispatch(setHighlightLocation(newPosition));
    }, [dispatch, max, position, range]);

    return (
            <div style={{marginLeft: '60px', marginRight: '25px'}}>
                <Stack direction='vertical'>
                    Range:
                    <Slider
                            value={range}
                            onChange={(event: Event, value: number | number[]) => setRange(value as [number, number])}
                            valueLabelDisplay="off"
                            max={max}
                            size='small'
                    />
                    Position:
                    <Slider onChange={(event: Event, value: number | number[]) => setPosition(value as number)}
                            value={position}
                            valueLabelDisplay='off'
                            min={range ? range[0] : 0}
                            max={range ? range[1] : 0}
                            size='small'
                    />
                </Stack>
            </div>
    );
}

export default Controls;
