import {getAuth, Auth} from "firebase/auth";
import firebaseBackend from "backend/firebaseBackend";

export default class AuthProvider {
    private static _instance: AuthProvider;
    public static get instance(): AuthProvider {
        if (!AuthProvider._instance) {
            AuthProvider._instance = new AuthProvider();
        }

        return AuthProvider._instance;
    }

    private constructor() {
    }

    _auth?: Auth;
    get auth(): Auth {
        if(!this._auth) {
            this._auth = getAuth(firebaseBackend);
        }
        return this._auth;
    }
}
