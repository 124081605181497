import {distanceBetween} from "geofire-common";
import Location from "../Location";
import ProcessedLocation from "../ProcessedLocation";
import {LngLat} from "mapbox-gl";
import AveragedProcessedLocation from "../AveragedProcessedLocation";
import {Point} from "ts-2d-geometry";

const minHeadingDistanceMeters = 0.2;

module ProcessedLocationTools {
    function clampAngle(v: number): number {
        let ret = v;
        while (ret <= -180) {
            ret += 360;
        }
        while (ret > 180) {
            ret -= 360;
        }
        return ret;
    }

    export function distanceInMetersBetweenLocations(a: Location, b: Location): number {
        return (
            distanceBetween([a.latitude, a.longitude], [b.latitude, b.longitude]) *
            1000
        );
    }

    export function distanceInMetersBetweenLngLatAndLocation(a: LngLat, b: Location): number {
        return (
            distanceBetween([a.lat, a.lng], [b.latitude, b.longitude]) *
            1000
        );
    }

    export function process(
        location: Location,
        prevLocations: ProcessedLocation[]
    ): ProcessedLocation {
        let headingDistanceMeters = 0;
        let headingIndex = prevLocations.length - 1;
        if (prevLocations.length > 0) {
            headingDistanceMeters +=
                distanceInMetersBetweenLocations(
                    location,
                    prevLocations[headingIndex].location,
                );
            while (
                headingDistanceMeters < minHeadingDistanceMeters &&
                headingIndex > 0
                ) {
                headingIndex--;
                headingDistanceMeters += distanceInMetersBetweenLocations(
                    prevLocations[headingIndex].location,
                    prevLocations[headingIndex + 1].location);
            }
        }
        let calculatedHeading: number;
        if (headingDistanceMeters < minHeadingDistanceMeters) {
            calculatedHeading = location.headingDegrees;
        } else {
            const headingValue = prevLocations[headingIndex];
            calculatedHeading = clampAngle(
                (Math.atan2(
                        location.longitude - headingValue.location.longitude,
                        location.latitude - headingValue.location.latitude,
                    ) *
                    180) /
                Math.PI,
            );
        }

        return {
            location: location,
            calculatedHeadingDegrees: calculatedHeading,
        }
    }

    function perpendicularDegrees(processedLocation: ProcessedLocation) {
        return -processedLocation.calculatedHeadingDegrees;
    }

    function perpendicularPointA(processedLocation: ProcessedLocation): Location {
        const latitude =
            processedLocation.location.latitude +
            Math.sin((perpendicularDegrees(processedLocation) * Math.PI) / 180) * 0.0002;
        const longitude =
            processedLocation.location.longitude +
            Math.cos((perpendicularDegrees(processedLocation) * Math.PI) / 180) * 0.0002;
        return {
            latitude,
            longitude,
            speedKpH: processedLocation.location.speedKpH,
            headingDegrees: processedLocation.location.headingDegrees,
            unixTimestampUTC: processedLocation.location.unixTimestampUTC,
        };
    }

    function perpendicularPointB(processedLocation: ProcessedLocation): Location {
        const latitude =
            processedLocation.location.latitude -
            Math.sin((perpendicularDegrees(processedLocation) * Math.PI) / 180) * 0.0002;
        const longitude =
            processedLocation.location.longitude -
            Math.cos((perpendicularDegrees(processedLocation) * Math.PI) / 180) * 0.0002;
        return {
            latitude,
            longitude,
            speedKpH: processedLocation.location.speedKpH,
            headingDegrees: processedLocation.location.headingDegrees,
            unixTimestampUTC: processedLocation.location.unixTimestampUTC,
        };
    }

    export function positionSide(processedLocation: ProcessedLocation, other: ProcessedLocation) {
        const perpendicularA = perpendicularPointA(processedLocation);
        const perpendicularB = perpendicularPointB(processedLocation);
        const position =
            (perpendicularB.longitude - perpendicularA.longitude) *
            (other.location.latitude - perpendicularA.latitude) -
            (perpendicularB.latitude - perpendicularA.latitude) *
            (other.location.longitude - perpendicularA.longitude);
        return Math.sign(position);
    }

    export function intersectionWith(processedLocation: ProcessedLocation, prevData: ProcessedLocation, currentData: ProcessedLocation) {
        const prevDistanceInMeters = distanceInMetersBetweenLocations(processedLocation.location, prevData.location);
        const currentDistanceInMeters = distanceInMetersBetweenLocations(processedLocation.location, currentData.location);
        // we have in m_candidate:
        // ------x--------------------S----------------------------------------------------x-----------
        //       |                    |                                                    |
        //       prevData             this                                                 currentData
        //       ---------------------|                                                    |
        //     prevDistanceInMeters   |                                                    |
        //                            |-----------------------------------------------------
        //                            |              currentDistanceInMeters
        //                            |
        //                            |
        //                            averaged
        const averaged = average(
            prevData,
            prevDistanceInMeters,
            currentData,
            currentDistanceInMeters,
        );
        // console.log(
        //   'intersectionWith\n',
        //   `title: 'start', position: new google.maps.LatLng(${this.location.latitude}, ${this.location.longitude})\n`,
        //   `title: 'prev', position: new google.maps.LatLng(${prevData.location.latitude}, ${prevData.location.longitude})\n`,
        //   `title: 'current', position: new google.maps.LatLng(${currentData.location.latitude}, ${currentData.location.longitude})\n`,
        //   `title: 'averaged', position: new google.maps.LatLng(${averaged.location.latitude}, ${averaged.location.longitude})\n`,
        // );

        const intersection = perpendicularIntersectionWith(processedLocation,
            prevData.location,
            currentData.location
        );
        // console.log(
        //   'intersection\n',
        //   `title: 'intersection', position: new google.maps.LatLng(${intersection.latitude}, ${intersection.longitude})\n`,
        // );

        return {
            location:
                {
                    latitude: intersection.latitude,
                    longitude: intersection.longitude,
                    speedKpH:
                    averaged.location.speedKpH,
                    headingDegrees:
                    averaged.location.headingDegrees,
                    unixTimestampUTC:
                    averaged.location.unixTimestampUTC,
                },
            calculatedHeadingDegrees: averaged.calculatedHeadingDegrees,
        };
    }

    function perpendicularIntersectionWith(processedLocation: ProcessedLocation, p21: Location, p22: Location): Location {
        const perpendicularA = perpendicularPointA(processedLocation);
        const perpendicularB = perpendicularPointB(processedLocation);
        // console.log(
        //   'perpendicular\n',
        //   `title: 'A', position: new google.maps.LatLng(${perpendicularA.latitude}, ${perpendicularA.longitude})\n`,
        //   `title: 'B', position: new google.maps.LatLng(${this.perpendicularPointB.latitude}, ${this.perpendicularPointB.longitude})\n`,
        // );
        const m =
            (p22.longitude - p21.longitude) *
            (perpendicularA.latitude - p21.latitude) -
            (p22.latitude - p21.latitude) *
            (perpendicularA.longitude - p21.longitude);
        const n =
            (p22.latitude - p21.latitude) *
            (perpendicularB.longitude -
                perpendicularA.longitude) -
            (p22.longitude - p21.longitude) *
            (perpendicularB.latitude - perpendicularA.latitude);

        const Ua = m / n;

        return {
            latitude: perpendicularA.latitude +
                Ua *
                (perpendicularB.latitude -
                    perpendicularA.latitude),
            longitude: perpendicularA.longitude +
                Ua *
                (perpendicularB.longitude -
                    perpendicularA.longitude),
            speedKpH: 0,
            headingDegrees: 0,
            unixTimestampUTC: 0,
        };
    }

    export function average(
        closest: ProcessedLocation,
        distanceToClosest: number,
        secondClosest: ProcessedLocation,
        distanceToSecondClosest: number
    ): AveragedProcessedLocation {
        const distancesSum = distanceToClosest + distanceToSecondClosest;
        if (distancesSum === 0) {
            return {
                ...closest,
                closest: closest,
                distanceToClosest: distanceToClosest,
                secondClosest: secondClosest,
                distanceToSecondClosest: distanceToSecondClosest,
                distance: distanceToClosest,
            }; // both have 0 dist
        } else {
            const prevFactor = distanceToSecondClosest / distancesSum;
            const postFactor = distanceToClosest / distancesSum;

            const averagedUnixTimestamp =
                closest.location.unixTimestampUTC * prevFactor +
                secondClosest.location.unixTimestampUTC * postFactor;
            const averagedHeading = clampAngle(
                closest.calculatedHeadingDegrees * prevFactor +
                secondClosest.calculatedHeadingDegrees * postFactor,
            );

            return {
                location: {
                    latitude: closest.location.latitude * prevFactor +
                        secondClosest.location.latitude * postFactor,
                    longitude: closest.location.longitude * prevFactor +
                        secondClosest.location.longitude * postFactor,
                    speedKpH: closest.location.speedKpH * prevFactor +
                        secondClosest.location.speedKpH * postFactor,
                    headingDegrees: averagedHeading,
                    unixTimestampUTC: averagedUnixTimestamp,
                },
                calculatedHeadingDegrees: averagedHeading,
                closest: closest,
                distanceToClosest: distanceToClosest,
                secondClosest: secondClosest,
                distanceToSecondClosest: distanceToSecondClosest,
            }
        }
    }

    export function getClosestPosition(data: ProcessedLocation, locations: ProcessedLocation[]): AveragedProcessedLocation {
        let closestIndex = -1;
        let distanceToClosest = Number.NaN;
        // find the index and distance to the closest point with similar heading
        for (let i = 0; i < locations.length; i++) {
            const headingEnd = Math.abs(
                data.calculatedHeadingDegrees -
                locations[i].calculatedHeadingDegrees,
            );
            if (headingEnd < 45) {
                // and similar time
                const currentDist = ProcessedLocationTools.distanceInMetersBetweenLocations(data.location, locations[i].location);
                if (
                    Number.isNaN(distanceToClosest) ||
                    currentDist < distanceToClosest
                ) {
                    distanceToClosest = currentDist;
                    closestIndex = i;
                }
            }
        }

        // if we found a point, or it's not too far
        if (closestIndex !== -1 && distanceToClosest < 40) {
            // we need the second-closest point
            let secondClosestIndex: number;

            const closest = locations[closestIndex];
            if (closestIndex === 0) {
                // if closest is the start, second closest is the next location
                secondClosestIndex = 1;
            } else if (closestIndex === locations.length - 1) {
                // if closest is the end, second closest is the previous location
                secondClosestIndex = locations.length - 2;
            } else {
                // if not we have to check the distances to previous and next locations
                let indexPrev = closestIndex - 1;
                while (ProcessedLocationTools.distanceInMetersBetweenLocations(closest.location, locations[indexPrev].location) === 0) {
                    indexPrev--;
                    if (indexPrev < 0) {
                        break;
                    }
                }
                let indexNext = closestIndex + 1;
                while (ProcessedLocationTools.distanceInMetersBetweenLocations(closest.location, locations[indexNext].location) === 0) {
                    indexNext++;
                    if (indexNext >= locations.length) {
                        indexNext = -1;
                        break;
                    }
                }

                const distWithPrev =
                    indexPrev < 0 ? Infinity :
                        ProcessedLocationTools.distanceInMetersBetweenLocations(data.location, locations[indexPrev].location);
                const distWithNext =
                    indexNext < 0 ? Infinity :
                        ProcessedLocationTools.distanceInMetersBetweenLocations(data.location, locations[indexNext].location);

                // and take the smaller one
                if (distWithNext === distWithPrev) {
                    console.warn('same distance with next and prev!');
                    // if same distance, ignore the closest, take next and prev
                    closestIndex = indexPrev;
                    distanceToClosest = distWithPrev;
                    secondClosestIndex = indexNext;
                } else if (distWithNext < distWithPrev) {
                    secondClosestIndex = indexNext;
                } else {
                    secondClosestIndex = indexPrev;
                }
            }

            const secondClosest = locations[secondClosestIndex];
            const ret = projectInSegment(
                data,
                closest,
                secondClosest
            )

            const distanceToRet = ProcessedLocationTools.distanceInMetersBetweenLocations(data.location, ret.location);
            if (distanceToRet > distanceToClosest) {
                // console.warn(index, 'distanceToRet > distanceToClosest', distanceToRet, distanceToClosest)
                // if distance to average is further than to closest (might happen at the start or at the end)
                // keep the closest
                return {
                    ...closest
                }
            }
            return ret;
        }

        return {
            ...data,
        };
    }

    function toPoint(location: ProcessedLocation): Point {
        return new Point(location.location.longitude, location.location.latitude);
    }

    function projectInSegment(location: ProcessedLocation, segmentA: ProcessedLocation, segmentB: ProcessedLocation):
        AveragedProcessedLocation {
        const pA = toPoint(segmentA);
        const pB = toPoint(segmentB);
        const line = pA.asLine(pB.minus(pA));

        const p = toPoint(location);

        const projected = line.project(p);

        const lng = projected.x;
        const lat = projected.y;
        const coords: LngLat = new LngLat(lng, lat)

        let distanceToA = ProcessedLocationTools.distanceInMetersBetweenLngLatAndLocation(coords, segmentA.location)
        const distanceToB = ProcessedLocationTools.distanceInMetersBetweenLngLatAndLocation(coords, segmentB.location)

        const distancesSum = distanceToA + distanceToB;
        if (distancesSum === 0) {
            distanceToA = 1;
        }

        const factorA = distanceToA / distancesSum;
        const factorB = distanceToB / distancesSum;

        const averagedUnixTimestamp =
            segmentA.location.unixTimestampUTC * factorA +
            segmentB.location.unixTimestampUTC * factorB;
        const averagedHeading = clampAngle(
            segmentA.calculatedHeadingDegrees * factorA +
            segmentB.calculatedHeadingDegrees * factorB,
        );
        const averagedSpeedKpH = segmentA.location.speedKpH * factorA + segmentB.location.speedKpH * factorB;
        return {
            location: {
                latitude: coords.lat,
                longitude: coords.lng,
                speedKpH: averagedSpeedKpH,
                headingDegrees: averagedHeading,
                unixTimestampUTC: averagedUnixTimestamp,
            },
            calculatedHeadingDegrees: averagedHeading,
            closest: segmentA,
            distanceToClosest: distanceToA,
            secondClosest: segmentB,
            distanceToSecondClosest: distanceToB,
        }
    }

    export function getAllClosestPositions(reference: ProcessedLocation[], locations: ProcessedLocation[]): AveragedProcessedLocation[] {
        const ret: AveragedProcessedLocation[] = [];

        let referenceIndex = 0;
        for (const location of reference) {
            const closestLocation = ProcessedLocationTools.getClosestPosition(
                location,
                locations.slice(
                    Math.max(0, referenceIndex - locations.length / 2),
                    Math.min(locations.length, referenceIndex + locations.length / 2)
                )
            )
            ret.push(closestLocation);
            referenceIndex++;
        }

        return ret;
    }

    export function getClosestPositionToLocation(location: LngLat, locations: ProcessedLocation[]): ProcessedLocation {
        let closestIndex: number | null = null;
        let distanceToClosest: number = Number.NaN;
        // find the index and distance to the closest point
        for (let i = 0; i < locations.length; i++) {
            const currentDist = ProcessedLocationTools.distanceInMetersBetweenLngLatAndLocation(location, locations[i].location);
            if (
                closestIndex === null ||
                currentDist < distanceToClosest
            ) {
                distanceToClosest = currentDist;
                closestIndex = i;
            }
        }
        return locations[closestIndex!];
    }
}

export default ProcessedLocationTools;
