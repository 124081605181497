import {useParams} from "react-router-dom";
import {Stack} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {selectSelectedSession, selectSessionById} from "redux/features/firestore/sessions/slice";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import "components/dock/Dock.scss";
import {defineCustomElements} from "igniteui-dockmanager/loader";
import {IgcDockManagerComponent, IgcDockManagerPaneType, IgcSplitPaneOrientation} from "igniteui-dockmanager";
import DockPane from "components/dock/DockPane";
import {selectTrackById} from "redux/features/firestore/tracks/slice";
import SessionMap from "./SessionMap";
import Controls from "./Controls";
import Info from "./Info";
import TracksProvider from "redux/features/firestore/tracks/TracksProvider";

/* eslint-disable */
declare global {
    namespace JSX {
        // tslint:disable-next-line:interface-name
        // noinspection JSUnusedGlobalSymbols
        interface IntrinsicElements {
            "igc-dockmanager": any;
        }
    }
}
/* eslint-enable */

defineCustomElements().catch(e => console.error(e));

const Session = () => {
    const params = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const selectSession = async () => {
            const track = await TracksProvider.instance.getTrackById(params.trackId!);
            dispatch(selectSessionById({
                track: track!,
                sessionId: params.sessionId!,
            }));
        };

        selectSession().catch(console.error)
    }, [dispatch, params.sessionId, params.trackId]);
    const selectedSession = useAppSelector(selectSelectedSession);

    useEffect(() => {
        if (selectedSession) {
            dispatch(selectTrackById(selectedSession.track));
        }
    }, [dispatch, selectedSession]);

    const [panes, setPanes] = useState<Map<string, DockPane> | undefined>(undefined);
    useEffect(() => {
        if (selectedSession) {
            const p = new Map();
            p.set(Controls.name, new DockPane(
                    Controls.name,
                    'Controls',
                    <Controls/>,
            ));
            p.set(Info.name, new DockPane(
                    Info.name,
                    'Info',
                    <Info/>,
            ));
            p.set(SessionMap.name, new DockPane(
                    SessionMap.name,
                    'Map',
                    <SessionMap/>,
            ));
            setPanes(p);
        } else {
            setPanes(undefined);
        }
    }, [selectedSession]);

    const [panesList, setPanesList] = useState<DockPane[] | undefined>(undefined);
    useEffect(() => {
        if (panes) {
            setPanesList(Array.from(panes.values()));
        } else {
            setPanesList(undefined);
        }
    }, [panes])

    useEffect(() => {
        if (panes) {
            const dockManager = document.getElementById("dockManager") as IgcDockManagerComponent;
            dockManager.layout = {
                rootPane: {
                    type: IgcDockManagerPaneType.splitPane,
                    orientation: IgcSplitPaneOrientation.horizontal,
                    panes: [
                        {
                            ...panes.get(SessionMap.name)!.pane,
                            size: 40,
                        },
                        {
                            type: IgcDockManagerPaneType.splitPane,
                            orientation: IgcSplitPaneOrientation.vertical,
                            size: 40,
                            panes: [
                                {
                                    ...panes.get(Controls.name)!.pane,
                                    size: 13,
                                },
                                {
                                    ...panes.get(Info.name)!.pane,
                                    size: 13,
                                },
                            ]
                        },
                    ]
                },
                floatingPanes: undefined,
            };
        }
    }, [panes]);

    return <>
        <Stack direction='vertical' style={{position: 'fixed', width: '100%', height: '95%'}}>
            <igc-dockmanager id="dockManager">
                {
                        panesList && panesList.map(pane => {
                            return (<div key={pane.contentId} slot={pane.contentId} className='pane'>{pane.element}</div>);
                        })
                }
            </igc-dockmanager>
        </Stack>
    </>;
}

export default Session;
