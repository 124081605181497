import React, {useEffect, useState} from "react";
import Slider from "@mui/material/Slider";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    selectHighlightSessionLocation,
    selectHighlightSessionRange,
    selectSelectedSession,
    setHighlightSessionLocation,
    setHighlightSessionRange
} from "redux/features/firestore/sessions/slice";
import {Stack} from "react-bootstrap";
import Session from "redux/features/firestore/sessions/Session";
import Grid from "@mui/material/Grid";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";

const Input = styled(MuiInput)`
  width: 42px;
`;
const Controls = () => {
    const [rangeMax, setRangeMax] = useState<number>(0);

    const selectedSession: Session = useAppSelector(selectSelectedSession);
    const range: [number, number] = useAppSelector(selectHighlightSessionRange);
    const position: number = useAppSelector(selectHighlightSessionLocation)

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (selectedSession) {
            const max: number = selectedSession.locations.length - 1;
            setRangeMax(max);
            if(max < range[1] || range[1] === 0) {
                dispatch(setHighlightSessionRange([0, max]));
            }
        } else {
            setRangeMax(0);
        }
    }, [selectedSession]);

    return (
            <div style={{marginLeft: '60px', marginRight: '25px'}}>
                <Stack direction='vertical'>
                    Range:
                    <Slider
                            value={range}
                            onChange={(event: Event, value: number | number[]) => dispatch(setHighlightSessionRange(value as [number, number]))}
                            valueLabelDisplay="off"
                            max={rangeMax}
                            size='small'
                    />
                    Position:
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <Slider onChange={(event: Event, value: number | number[]) => {
                                dispatch(setHighlightSessionLocation(value as number));
                            }}
                                    value={position}
                                    valueLabelDisplay='off'
                                    min={range ? range[0] : 0}
                                    max={range ? range[1] : 0}
                                    size='small'
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                    value={position}
                                    size="small"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        dispatch(setHighlightSessionLocation(event.target.value === '' ? 0 : Number(event.target.value)))
                                    }}
                                    inputProps={{
                                        step: 1,
                                        min: 0,
                                        max: range[1],
                                        type: 'number',
                                        'aria-labelledby': 'input-slider',
                                    }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </div>
    );
}

export default Controls;
