import React from "react";
import {Link, Stack} from "@mui/material";
import {Formik, FormikValues} from "formik";
import * as yup from "yup";
import {Button, Form} from "react-bootstrap";
import ValidatedFormControl from "components/ValidatedFormControl";
import {setYoutubeKey} from "redux/features/user/slice";
import {useAppDispatch} from "redux/hooks";

const formSchema = yup.object().shape({
    youtubeKey: yup.string().required('The YouTube-Live Stream key is required'),
});

// https://developers.google.com/youtube/v3/live/getting-started
// https://github.com/google/google-api-javascript-client?tab=readme-ov-file
const Live = () => {
    const dispatch = useAppDispatch();
    const clientId = '398397549624-u4kfrb6et8jclnogf4jjtu44lq812si2.apps.googleusercontent.com';
    const clientSecret = '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';
    const redirectUrl = `${window.location.origin}/youtube_back`;
    const scope = `https://www.googleapis.com/auth/youtube`;

    console.log(redirectUrl);

    return (<>
        <>
            <a href={`https://accounts.google.com/o/oauth2/v2/auth`+
            `?client_id=${clientId}`+
            `&redirect_uri=${encodeURI(redirectUrl)}`+
            `&response_type=token`+
            `&scope=${encodeURI(scope)}`}>Link youtube</a>
        </>
        <Formik
            validationSchema={formSchema}
            initialValues={{
                youtubeKey: ''
            }}
            onSubmit={(values: FormikValues, actions: any) => {
                dispatch(setYoutubeKey(values.youtubeKey));
                actions.setSubmitting(false);
            }}
        >
            {(formikProps) => (
                <Form noValidate onSubmit={formikProps.handleSubmit}>
                    <Stack spacing={3}
                           margin='auto'
                           paddingX={6}
                           paddingY={4}>
                        <ValidatedFormControl label='YouTube-Live Stream Key' type='password'
                                              placeholder='Enter your key'
                                              formikProps={formikProps} field='youtubeKey'/>

                        <Link
                            href='https://support.google.com/youtube/answer/2907883#zippy=%2Cstart-live-streaming-now'>
                            How to get my YouTube-Live Stream Key
                        </Link>
                        <Button
                            disabled={formikProps.isSubmitting}
                            type="submit">
                            Save
                        </Button>
                        <Button
                            disabled={formikProps.isSubmitting}
                            onClick={() => {
                                dispatch(setYoutubeKey(null))
                                formikProps.values['youtubeKey'] = ''
                            }}
                            variant="danger">
                            Delete my key
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
    </>);
}

export default Live;
