import {
    configureStore
} from "@reduxjs/toolkit";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    StateReconciler,
    persistStore,
} from "redux-persist";
import rootReducer from "./reducers";
import {persistFilter as userPersistFilter} from "./features/user/slice";
import {persistFilter as toastsPersistFilter} from "./features/toasts/slice";
import {persistFilter as firestorePersistFilter} from "./features/firestore/slice";
import {persistFilter as tracksPersistFilter} from "./features/firestore/tracks/slice";
import {persistFilter as sessionsPersistFilter} from "./features/firestore/sessions/slice";

import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import localStorage from "redux-persist/es/storage";

const persistConfig = {
    key: 'root',
    version: 1,
    storage: localStorage,
    stateReconciler: autoMergeLevel2 as StateReconciler<any>,
    transforms: [
        userPersistFilter,
        toastsPersistFilter,
        firestorePersistFilter,
        tracksPersistFilter,
        sessionsPersistFilter,
    ]
};
const persistedReducer = persistReducer(
    persistConfig,
    rootReducer
);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                warnAfter: 1000,
            },
            immutableCheck: {
                warnAfter: 1000,
            },
        }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
