import Track from "../../tracks/Track";
import ProcessedLap from "./ProcessedLap";
import ProcessedLocation from "../ProcessedLocation";
import ProcessedLocationTools from "./ProcessedLocationTools";
import Location from "../Location";

const distanceInMetersToAddToLapLocations = 0.5;
const minMetersTrackLength = 100;
const maxHeadingDegreesThreshold = 45;

// noinspection JSUnusedLocalSymbols
class RealTimeRaceProcessing {
    readonly track: Track;
    readonly startLocation: ProcessedLocation;

    onLog: (m: string)=> void = () => {};

    constructor(track: Track) {
        this.track = track;
        this.startLocation = {
            location: {
                latitude: this.track.startLocation.latitude,
                longitude: this.track.startLocation.longitude,
                headingDegrees: this.track.startRotation,
                speedKpH: 0,
                unixTimestampUTC: 0,
            },
            calculatedHeadingDegrees: this.track.startRotation,
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private log(m: string) {
        this.onLog(m);
        // console.log(m);
    }

    readonly processedLocations: ProcessedLocation[] = [];
    readonly laps: ProcessedLap[] = [];
    currentLap: ProcessedLap | null = null;

    private currentLapDistanceInMeters: number = 0;
    totalDistanceInMeters: number = 0;
    prevSide: number = 0;
    bestLap: ProcessedLap | null = null;

    // noinspection JSUnusedGlobalSymbols
    addLocation(_currentLocation: Location): ProcessedLocation {
        const currentLocation = ProcessedLocationTools.process(
            _currentLocation,
            this.processedLocations,
        );
        // it is the first data, just add it
        if (this.processedLocations.length === 0) {
            // this.log('First data');
            this.currentLapDistanceInMeters = 0;
            this.totalDistanceInMeters = 0;
            this.processedLocations.push(currentLocation);
            this.prevSide = 0;
            this.laps.splice(0);
            this.currentLap = null;
            return currentLocation;
        }

        const distanceInMetersWithPrevious =
            ProcessedLocationTools.distanceInMetersBetweenLocations(
                this.processedLocations[this.processedLocations.length - 1].location,
                currentLocation.location);
        if (distanceInMetersWithPrevious < distanceInMetersToAddToLapLocations) {
            this.log(
                `Didn't move enough: ${
                    this.processedLocations[this.processedLocations.length - 1].location
                        .latitude
                }, ${
                    this.processedLocations[this.processedLocations.length - 1].location
                        .longitude
                } vs ${currentLocation.location.latitude}, ${
                    currentLocation.location.longitude
                } => ${distanceInMetersWithPrevious}`,
            );
            return currentLocation;
        }

        this.processedLocations.push(currentLocation);
        if (this.currentLap != null) {
            this.currentLap.addLocation(currentLocation);
        }
        this.currentLapDistanceInMeters += distanceInMetersWithPrevious;
        this.totalDistanceInMeters += distanceInMetersWithPrevious;

        const headingWithEnd = Math.abs(
            currentLocation.calculatedHeadingDegrees -
            this.startLocation.calculatedHeadingDegrees,
        );
        if (headingWithEnd > maxHeadingDegreesThreshold) {
            // this.log('Not aligned with start');
            this.prevSide = 0;
            return currentLocation;
        }

        if (this.laps.length > 0) {
            const lastLap = this.laps[this.laps.length - 1];
            if (this.currentLapDistanceInMeters < minMetersTrackLength) {
                this.log('lap not long enough (min)');
                this.prevSide = 0;
                return currentLocation;
            }
            if (this.currentLapDistanceInMeters < lastLap.distanceInMeters * .8) {
                this.log('lap not long enough');
                this.prevSide = 0;
                return currentLocation;
            }
        }

        const distanceInMetersToStart = ProcessedLocationTools.distanceInMetersBetweenLocations(
            this.startLocation.location,
            currentLocation.location
        );
        // at 300kph, each second the car travels 83.333 meters, so the start should be closer than those 83m
        if (distanceInMetersToStart > 100) {
            this.log('Too far from start');
            this.prevSide = 0;
            return currentLocation;
        }

        const currentSide = ProcessedLocationTools.positionSide(this.startLocation, currentLocation);
        if (this.prevSide === 0) {
            // this.log('No info about side');
            this.prevSide = currentSide;
            return currentLocation;
        }
        if (this.prevSide !== currentSide) {
            const averaged = ProcessedLocationTools.intersectionWith(this.startLocation,
                this.processedLocations[this.processedLocations.length - 2],
                currentLocation,
            );

            this.processedLocations.pop();
            this.processedLocations.push(averaged);

            if (this.currentLap != null) {
                this.currentLap.completeLap(averaged);
                this.laps.push(this.currentLap);
                this.log(`LAP! ${this.currentLap.timeInSeconds}s`);
                if (
                    this.bestLap === null ||
                    this.bestLap.timeInSeconds > this.currentLap.timeInSeconds
                ) {
                    this.log('BEST LAP!');
                    this.bestLap = this.currentLap;
                }
            } else {
                //this.log('FIRST LAP!');
            }

            this.currentLap = new ProcessedLap();
            this.currentLap.addLocation(averaged);

            this.currentLapDistanceInMeters = ProcessedLocationTools.distanceInMetersBetweenLocations(
                currentLocation.location,
                averaged.location
            );
        }
        this.prevSide = currentSide;
        return currentLocation;
    }
}

export default RealTimeRaceProcessing;
