import {Link, Navigate, Route, Routes} from "react-router-dom";
import ForgotPassword from "./credentials/ForgotPassword";
import SignUp from "./credentials/SignUp";
import React from "react";
import {useAppSelector} from "redux/hooks";
import {selectInitialized, selectUser} from "redux/features/user/slice";
import LoadingModal from "components/LoadingModal";
import SignIn from "./credentials/SignIn";
import Error from "./members/Error";
import NotVerified from "./credentials/NotVerified";
import Main from "./members/main/Main";
import Home from "./members/main/Home";
import Track from "./members/main/track/Track";
import Session from "./members/main/session/Session";
import Account from "./members/main/settings/Account";
import Live from "./members/main/settings/Live";

const RootNavigation = () => {
    const initialized = useAppSelector(selectInitialized);
    const user = useAppSelector(selectUser);
    if (!initialized) {
        return <LoadingModal loading={true}/>;
    } else {
        return (
            <Routes>
                <Route path="*" element={<Navigate to={!user ? "/" : "/members"} replace/>}/>
                <Route index element={<>
                    <Link to='/sign-in'>Sign in</Link>
                    <br/>
                    <Link to='/sign-up'>Sign up</Link>
                    <br/>
                    5
                </>}/>
                {!user && <Route>
                    <Route path='/sign-in' element={<SignIn/>}/>
                    <Route path='/forgot-password' element={<ForgotPassword/>}/>
                    <Route path='/sign-up' element={<SignUp/>}/>
                </Route>}
                {user &&
                    <Route path="/members"
                           errorElement={<Error/>}
                    >
                        {!user.emailVerified && <Route>
                            <Route element={<Main/>}>
                                <Route index element={<NotVerified/>}/>
                                <Route path='account-settings' element={<Account/>}/>
                            </Route>
                        </Route>}
                        {user.emailVerified && <Route>
                            <Route element={<Main/>}>
                                <Route index element={<Home/>}/>
                                <Route path=':trackId' element={<Track/>}/>
                                <Route path=':trackId/:sessionId' element={<Session/>}/>
                                <Route path='account-settings' element={<Account/>}/>
                                <Route path='live-settings' element={<Live/>}/>
                            </Route>
                        </Route>}
                    </Route>
                }
            </Routes>);
    }
};

export default RootNavigation;
