import {createWhitelistFilter} from "redux-persist-transform-filter";
import {createSlice} from "@reduxjs/toolkit";
import {isFulfilledAction, isPendingAction, isRejectedAction} from "../helpers";
import {RootState} from "../../store";

export interface FirestoreState {
    loading: boolean;
    backendError: any | undefined;
}

const initialState: FirestoreState = {
    loading: true,
    backendError: undefined,
};

export const sliceName = 'firestore';

export const persistFilter = createWhitelistFilter(
    sliceName,
    []
);

export const firestoreSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.backendError = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isPendingAction(sliceName), (state) => {
                // console.log(action.type);
                state.loading = true;
                state.backendError = undefined;
            })
            .addMatcher(isFulfilledAction(sliceName), (state) => {
                // console.log(action.type);
                state.loading = false;
                state.backendError = undefined;
            })
            .addMatcher(isRejectedAction(sliceName), (state, action) => {
                // console.log(action.type);
                state.loading = false;
                state.backendError = action.error;
            })
        ;
    },
});

export const selectBackendLoading = (state: RootState) => state.firestore.loading;
export const selectBackendError = (state: RootState) => state.firestore.backendError;

export default firestoreSlice.reducer;
