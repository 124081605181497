import {store} from "../../../store";
import Session from "./Session";
import {
    collection,
    Firestore,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    QueryConstraint,
    startAfter,
    doc, DocumentSnapshot, updateDoc, getDoc
} from "firebase/firestore";
import firebaseBackend from "../../../../backend/firebaseBackend";
import Lap from "./Lap";
import LapTools from "./LapTools";
import Track from "../tracks/Track";

export default class SessionsProvider {
    private static _instance: SessionsProvider;
    public static get instance(): SessionsProvider {
        if (!SessionsProvider._instance) {
            SessionsProvider._instance = new SessionsProvider();
        }

        return SessionsProvider._instance;
    }

    private constructor() {
    }

    _firestore?: Firestore;
    get firestore(): Firestore {
        if (!this._firestore) {
            this._firestore = getFirestore(firebaseBackend);
        }
        return this._firestore;
    }

    private async getSessionFromDocSnapshot(track: Track, doc: DocumentSnapshot) {
        const docData = doc.data()!;
        const lapsWithoutSession = !docData.laps ? [] : docData.laps;
        const laps: Lap[] = [];
        for (const lapsWithoutSessionElement of lapsWithoutSession) {
            laps.push({
                ...lapsWithoutSessionElement,
                sessionId: doc.id,
            })
        }
        const session: Session = {
            id: doc.id,
            track: track,
            bestLap: docData.bestLap,
            laps: laps,
            locations: docData.locations,
            timestampUTCSeconds: docData.timestampUTC.seconds,
        };
        if (session.laps.length === 0 || session.laps.find((l: Lap) => !l.locations)) {
            console.warn('Session needs processing', session);
            await LapTools.process(session);
        }
        return session;
    }

    async getSessionsForTrack(track: Track, nb: number, lastSession: Session | null) : Promise<Session[]> {
        const storeState = store.getState();

        const constraints: QueryConstraint[] = [
            orderBy("timestampUTC", "desc"),
        ];

        if (lastSession != null) {
            const lastSessionDocRef = doc(this.firestore,
                `users/${storeState.user.userData.uid}/tracks/${track.id}/sessions`, lastSession.id);
            const lastSessionSnapshot = await getDoc(lastSessionDocRef);
            constraints.push(startAfter(lastSessionSnapshot));
        }
        constraints.push(limit(nb));

        const q = query(
            collection(this.firestore, `users/${storeState.user.userData.uid}/tracks/${track.id}/sessions/`),
            ...constraints
        );
        const snapshot = await getDocs(q);

        const ret: Session[] = [];
        for (const doc of snapshot.docs) {
            const session: Session = await this.getSessionFromDocSnapshot(track, doc);
            ret.push(session);
        }

        return ret;
    }

    async getSessionById(track: Track, id: string) {
        const storeState = store.getState();

        const docRef = doc(this.firestore,
            `users/${storeState.user.userData.uid}/tracks/${track.id}/sessions`, id);
        const docSnap = await getDoc(docRef);
        if(!docSnap.exists()) {
            return undefined;
        } else {
            return this.getSessionFromDocSnapshot(track, docSnap);
        }
    }


    async updateSessionLaps(session: Session, sessionLaps: Lap[]) {
        const storeState = store.getState();

        const sessionDocRef = doc(this.firestore,
            `users/${storeState.user.userData.uid}/tracks/${session.track.id}/sessions`, session.id);
        let bestLap: Lap | null = null;
        for (const lap of sessionLaps) {
            if (bestLap === null || bestLap.time > lap.time) {
                bestLap = lap;
            }
        }
        await updateDoc(sessionDocRef, {
            laps: sessionLaps,
            bestLap: bestLap,
        });
        session.laps = sessionLaps;
    }

    async updateLapHue(lap: Lap, hue: number) {
        const storeState = store.getState();

        const sessionDocRef = doc(this.firestore,
            `users/${storeState.user.userData.uid}/tracks/${lap.session.track.id}/sessions`,
            lap.session.id);
        const sessionDocSnap = await getDoc(sessionDocRef);
        const session = await this.getSessionFromDocSnapshot(lap.session.track, sessionDocSnap);

        for (const newLap of session.laps) {
            if(LapTools.Equals(newLap, lap)) {
                newLap.hue = hue;
            }
        }
        await updateDoc(sessionDocRef, {
            laps: session.laps,
        });
    }
}
