import React from "react";
import {Button, Modal} from "react-bootstrap";

export interface ErrorModalProps {
    error: string | null;
    setError: (v: string | null) => void;
}

const ErrorModal = ({error, setError}: ErrorModalProps) => {
    return <Modal
            show={!!error}
            onHide={() => setError(null)}
            backdrop="static"
            keyboard={false}
            centered
    >
        <Modal.Header closeButton>
            <Modal.Title>An error happened</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => setError(null)}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
};

export default ErrorModal;
