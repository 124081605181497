import {Modal, Spinner} from "react-bootstrap";
import React from "react";

export interface LoadingModalProps {
    loading: boolean
}

const LoadingModal = ({loading}: LoadingModalProps) => {
    return <Modal
            show={loading}
            backdrop="static"
            keyboard={false}
            centered
    >
        <Modal.Header>
            <Modal.Title style={{margin: 'auto'}} >Loading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Spinner style={{margin: '20% 50%'}} animation='border' />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
    </Modal>    
};

export default LoadingModal;
