import {PathLayer} from "@deck.gl/layers/typed";
import {Layer} from "@deck.gl/core/typed";
import ProcessedLocation from "redux/features/firestore/sessions/ProcessedLocation";
import LapTools from "redux/features/firestore/sessions/LapTools";
import {useControl} from "react-map-gl";
import {MapboxOverlay, MapboxOverlayProps} from "@deck.gl/mapbox/typed";
import {useAppSelector} from "redux/hooks";
import {
    selectFastestShownLap,
    selectHighlightRange,
    selectShownLaps,
} from "redux/features/firestore/sessions/slice";
import {useEffect, useState} from "react";
import {LapLayerData} from "./LapLayerData";

function DeckGLOverlay(props: MapboxOverlayProps & {
    interleaved?: boolean;
}) {
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
    overlay.setProps(props);
    return null;
}

const RangedLapsLayer = () => {
    const fastestLap = useAppSelector(selectFastestShownLap);
    const shownLaps = useAppSelector(selectShownLaps);

    const [layer, setLayer] = useState<Layer | null>(null);
    const [fullData, setFullData] = useState<LapLayerData[]>([]);

    useEffect(() => {
        const currentData: LapLayerData[] = [];
        if (shownLaps) {
            for (const shownLap of shownLaps) {
                const {r, g, b} = LapTools.hueToRgb(shownLap.lap.hue);
                currentData.push({
                    path: shownLap.calculatedLocations.map((l: ProcessedLocation) => [l.location.longitude, l.location.latitude]),
                    color: [r, g, b, 180],
                    fastest: LapTools.Equals(shownLap.lap, fastestLap),
                });
            }
        }
        setFullData(currentData);
    }, [fastestLap, shownLaps]);

    const highlightRange: [number, number] | null = useAppSelector(selectHighlightRange);
    const [data, setData] = useState<LapLayerData[]>([]);

    useEffect(() => {
        if (highlightRange && fullData) {
            setData(fullData.map((lapData: LapLayerData) => {
                return {
                    ...lapData,
                    path: lapData.path.slice(highlightRange[0], highlightRange[1] + 1)};
            }));
        } else {
            setData(fullData);
        }
    }, [highlightRange, fullData]);

    useEffect(() => {
        setLayer(new PathLayer({
            id: RangedLapsLayer.name,
            data: data,
            widthMinPixels: 1,
            getPath: d => d.path,
            getColor: d => d.color,
            getWidth: d => d.fastest ? 2 : 1
        }));
    }, [data])

    return <DeckGLOverlay layers={[layer]}/>
}
export default RangedLapsLayer;
