import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState, store} from "../../../store";
import Session from "./Session";
import {createWhitelistFilter} from "redux-persist-transform-filter";
import {sliceName as parentSliceMame} from "../slice";
import SessionsProvider from "./SessionsProvider";
import Lap from "./Lap";
import LapTools from "./LapTools";
import Track from "../tracks/Track";
import ProcessedLocationTools from "./Processing/ProcessedLocationTools";
import AveragedProcessedLocation from "./AveragedProcessedLocation";

export interface ShownLap {
    lap: Lap,
    calculatedLocations: AveragedProcessedLocation[] | null,
}

export interface SessionsState {
    sessionsInTrack: Session[] | undefined;
    sessionsInTrackHasMoreItems: boolean;

    fastestShownLapPerTrack: { [trackId: string]: Lap };
    shownLapsPerTrack: { [trackId: string]: ShownLap[] };

    highlightRange: [number, number] | null;
    highlightLocation: number | null;

    selectedSession: Session | undefined;
    highlightSessionRange: [number, number] | null;
    highlightSessionLocation: number | null;

}

const initialState: SessionsState = {
    sessionsInTrack: undefined,
    sessionsInTrackHasMoreItems: true,
    fastestShownLapPerTrack: {},
    shownLapsPerTrack: {},
    highlightRange: null,
    highlightLocation: null,
    selectedSession: undefined,
    highlightSessionRange: null,
    highlightSessionLocation: null,
};

export const sliceName = 'sessions';
export const persistFilter = createWhitelistFilter(
    sliceName,
    ['fastestShownLapPerTrack', 'shownLapsPerTrack', 'highlightSessionRange', 'highlightSessionLocation']
);

const pageItemsCount = 20;
export const getSessionsForTrack = createAsyncThunk(
    `${parentSliceMame}/${sliceName}/getSessionsForTrack`,
    async (track: Track) => {
        const storeState = store.getState();
        let lastSession: Session | null = null;
        if (storeState.sessions.sessionsInTrack && storeState.sessions.sessionsInTrack.length > 0) {
            if (storeState.sessions.sessionsInTrack[0].track.id === track.id) {
                lastSession = storeState.sessions.sessionsInTrack[storeState.sessions.sessionsInTrack.length - 1];
            }
        }
        return await SessionsProvider.instance.getSessionsForTrack(track, pageItemsCount, lastSession);
    }
);

export interface LapAndTrack {
    lap: Lap,
    track: Track,
}

export interface HueLapAndTrack {
    hue: number,
    lap: Lap,
    track: Track,
}

let setHueTimeout: { [keyId: string]: NodeJS.Timeout } = {};
const updateHueInFirebase = (lap: Lap, hue: number) => {
    const timeoutKey = `${lap.session.id}-${lap.originalNumber}`;

    if (timeoutKey in setHueTimeout) {
        clearTimeout(setHueTimeout[timeoutKey]);
    }

    const myTimeout = setHueTimeout[timeoutKey] = setTimeout(() => {
        SessionsProvider.instance.updateLapHue(lap, hue).catch(console.error);
        if (setHueTimeout[timeoutKey] === myTimeout) {
            delete setHueTimeout[timeoutKey];
        }
    }, 1000);
}

export interface TrackAndSessionId {
    track: Track,
    sessionId: string,
}

export const selectSessionById = createAsyncThunk(
    `${parentSliceMame}/${sliceName}/getSessionById`,
    async ({track, sessionId}: TrackAndSessionId) => {
        return await SessionsProvider.instance.getSessionById(track, sessionId);
    }
);

export const sessionsSlice = createSlice({
    name: sliceName,
    initialState: initialState,
    reducers: {
        clearSelectedSessions: (state) => {
            state.sessionsInTrack = undefined;
            state.sessionsInTrackHasMoreItems = true;

            state.fastestShownLapPerTrack = {};
            state.shownLapsPerTrack = {};

            state.highlightRange = null;
            state.highlightLocation = null;

            state.selectedSession= undefined;
            state.highlightSessionRange = null;
            state.highlightSessionLocation= null;
        },
        showLap: (state, action: PayloadAction<LapAndTrack>) => {
            const {track, lap} = action.payload;

            if (
                (track.id in state.shownLapsPerTrack &&
                    state.shownLapsPerTrack[track.id].some((l: ShownLap) => LapTools.Equals(l.lap, lap)))) {
                return;
            }

            const newShownLaps: ShownLap[] = [];
            if (!(track.id in state.shownLapsPerTrack) ||
                !(track.id in state.fastestShownLapPerTrack) ||
                state.fastestShownLapPerTrack[track.id].time > lap.time) {
                const newFastestShownLapPerTrack = {
                    ...state.fastestShownLapPerTrack,
                };
                newFastestShownLapPerTrack[track.id] = lap;

                newShownLaps.push({
                    lap: lap,
                    calculatedLocations: lap.locations!,
                });
                if (track.id in state.shownLapsPerTrack) {
                    for (const shownLap of state.shownLapsPerTrack[track.id]) {
                        if (!LapTools.Equals(shownLap.lap, lap)) {
                            const calculatedLocations = ProcessedLocationTools.getAllClosestPositions(lap.locations!, shownLap.lap.locations!);

                            newShownLaps.push({
                                lap: shownLap.lap,
                                calculatedLocations: calculatedLocations,
                            })
                        }
                    }
                }
                state.fastestShownLapPerTrack = newFastestShownLapPerTrack;
            } else {
                newShownLaps.push(...state.shownLapsPerTrack[track.id]);
                newShownLaps.push({
                    lap: lap,
                    calculatedLocations: ProcessedLocationTools.getAllClosestPositions(
                        state.fastestShownLapPerTrack[track.id].locations!,
                        lap.locations!
                    )
                });
            }
            const newShownLapsPerTrack = {
                ...state.shownLapsPerTrack
            };
            newShownLapsPerTrack[track.id] = newShownLaps;
            state.shownLapsPerTrack = newShownLapsPerTrack;
        },
        hideLap: (state, action: PayloadAction<LapAndTrack>) => {
            const {track, lap} = action.payload;

            if (!(track.id in state.shownLapsPerTrack) ||
                !(track.id in state.fastestShownLapPerTrack) ||
                !state.shownLapsPerTrack[track.id].some((l: ShownLap) => LapTools.Equals(l.lap, lap))
            ) {
                return;
            }

            const newShownLaps: ShownLap[] = [...state.shownLapsPerTrack[track.id].filter((l: ShownLap) => !LapTools.Equals(l.lap, lap))];

            if (LapTools.Equals(state.fastestShownLapPerTrack[track.id], lap)) {
                let newFastestShownLap: Lap | null = null;
                for (const shownLap of newShownLaps) {
                    if (!LapTools.Equals(shownLap.lap, lap)) {
                        if (newFastestShownLap === null || newFastestShownLap.time > shownLap.lap.time) {
                            newFastestShownLap = shownLap.lap;
                        }
                    }
                }

                const newFastestShownLapPerTrack = {
                    ...state.fastestShownLapPerTrack
                };
                if (newFastestShownLap === null) {
                    delete newFastestShownLapPerTrack[track.id];
                } else {
                    newFastestShownLapPerTrack[track.id] = newFastestShownLap;
                }
                state.fastestShownLapPerTrack = newFastestShownLapPerTrack;
            }
            const newShownLapsPerTrack = {
                ...state.shownLapsPerTrack
            };
            newShownLapsPerTrack[track.id] = newShownLaps;
            state.shownLapsPerTrack = newShownLapsPerTrack;
        },
        setLapHue: (state, action: PayloadAction<HueLapAndTrack>) => {
            const {lap, hue, track} = action.payload;
            if (lap.hue === hue) {
                return;
            }

            if (!(track.id in state.shownLapsPerTrack) ||
                !(track.id in state.fastestShownLapPerTrack) ||
                !state.shownLapsPerTrack[track.id].some((l: ShownLap) => LapTools.Equals(l.lap, lap))
            ) {
                return;
            }

            updateHueInFirebase(lap, hue);

            const newShownLaps: ShownLap[] = [...state.shownLapsPerTrack[track.id].filter((l: ShownLap) => !LapTools.Equals(l.lap, lap))];
            newShownLaps.push({
                lap: {
                    ...lap,
                    hue: hue,
                },
                calculatedLocations: state.shownLapsPerTrack[track.id].find((l: ShownLap) => LapTools.Equals(l.lap, lap))!.calculatedLocations
            })

            if (LapTools.Equals(state.fastestShownLapPerTrack[track.id], lap)) {
                const newFastestShownLapPerTrack = {
                    ...state.fastestShownLapPerTrack
                };
                newFastestShownLapPerTrack[track.id] = {
                    ...lap,
                    hue: hue,
                };
                state.fastestShownLapPerTrack = newFastestShownLapPerTrack;
            }
            const newShownLapsPerTrack = {
                ...state.shownLapsPerTrack
            };
            newShownLapsPerTrack[track.id] = newShownLaps;
            state.shownLapsPerTrack = newShownLapsPerTrack;
        },
        setHighlightRange: (state, action: PayloadAction<[number, number] | null>) => {
            state.highlightRange = action.payload;
        },
        setHighlightLocation: (state, action: PayloadAction<number | null>) => {
            state.highlightLocation = action.payload;
        },
        setHighlightSessionRange: (state, action: PayloadAction<[number, number] | null>) => {
            state.highlightSessionRange = action.payload;
        },
        setHighlightSessionLocation: (state, action: PayloadAction<number | null>) => {
            state.highlightSessionLocation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSessionsForTrack.fulfilled, (state, action) => {
                let newSessions: Session[] = [...action.payload];
                if (state.sessionsInTrack) {
                    newSessions.push(...state.sessionsInTrack)
                }
                newSessions = newSessions.sort((a, b) => a.timestampUTCSeconds < b.timestampUTCSeconds ? 1 : -1);
                state.sessionsInTrack = newSessions;
                state.sessionsInTrackHasMoreItems = action.payload.length === pageItemsCount;
            })
            .addCase(selectSessionById.fulfilled, (state, action) => {
                state.selectedSession = action.payload;
            })
    },
});

export const {
    clearSelectedSessions, showLap, hideLap, setLapHue,
    setHighlightRange, setHighlightLocation,
    setHighlightSessionRange, setHighlightSessionLocation
} = sessionsSlice.actions;
export const selectMostRecentSessions = (state: RootState) => state.sessions.mostRecentSessionsList;
export const selectMostRecentSessionsHasMoreItems = (state: RootState) => state.sessions.mostRecentSessionsListHasMoreItems;
export const selectSessionsInTrack = (state: RootState) => state.sessions.sessionsInTrack;
export const selectSessionsInTrackHasMoreItems = (state: RootState) => state.sessions.sessionsInTrackHasMoreItems;
export const selectFastestShownLap = (state: RootState) => state.tracks.selectedTrack.id in state.sessions.fastestShownLapPerTrack ? state.sessions.fastestShownLapPerTrack[state.tracks.selectedTrack.id] : null;
export const selectShownLaps = (state: RootState) => state.tracks.selectedTrack.id in state.sessions.shownLapsPerTrack ? state.sessions.shownLapsPerTrack[state.tracks.selectedTrack.id] : null;
export const selectHighlightRange = (state: RootState) => {
    return state.sessions.highlightRange && state.tracks.selectedTrack.id in state.sessions.fastestShownLapPerTrack ?
        ((
            state.sessions.highlightRange[0] < state.sessions.fastestShownLapPerTrack[state.tracks.selectedTrack.id].locations.length &&
            state.sessions.highlightRange[1] < state.sessions.fastestShownLapPerTrack[state.tracks.selectedTrack.id].locations.length
        ) ? state.sessions.highlightRange : null)
        : null;
}
export const selectHighlightLocation = (state: RootState) => state.tracks.selectedTrack.id in state.sessions.fastestShownLapPerTrack ?
    (state.sessions.highlightLocation < state.sessions.fastestShownLapPerTrack[state.tracks.selectedTrack.id].locations.length ? state.sessions.highlightLocation : null)
    : null;
export const selectSelectedSession = (state: RootState) => state.sessions.selectedSession;
export const selectHighlightSessionRange = (state: RootState) => {
    return state.sessions.highlightSessionRange && state.sessions.selectedSession ?
        ((
            state.sessions.highlightSessionRange[0] < state.sessions.selectedSession.locations.length &&
            state.sessions.highlightSessionRange[1] < state.sessions.selectedSession.locations.length
        ) ? state.sessions.highlightSessionRange : null)
        : null;
}
export const selectHighlightSessionLocation = (state: RootState) => state.sessions.highlightSessionLocation;

export default sessionsSlice.reducer;
