import {useCallback, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "redux/hooks";
import FlatList from "flatlist-react";
import {selectBackendLoading} from "redux/features/firestore/slice";
import {
    getTracksByMostRecentSession,
    selectTracksByMostRecentSession, selectTracksByMostRecentSessionHasMoreItems
} from "redux/features/firestore/tracks/slice";
import Track from "redux/features/firestore/tracks/Track";
import TrackCell from "./TrackCell";

const Home = () => {
    const dispatch = useAppDispatch();

    const loadMoreData = useCallback(() => {
        dispatch(getTracksByMostRecentSession());
    }, [dispatch]);

    useEffect(() => {
        loadMoreData();
    }, [dispatch, loadMoreData]);

    const loading = useAppSelector(selectBackendLoading);
    const tracksByMostRecentSession = useAppSelector(selectTracksByMostRecentSession);
    const hasMoreItems = useAppSelector(selectTracksByMostRecentSessionHasMoreItems);

    const renderItem = (track: Track, index: number) => {
        return <TrackCell key={index} track={track} />;
    }
    const showBlank = () => {
        if (!tracksByMostRecentSession && loading) {
            return <div>Loading tracks...</div>
        }
        return <div>No tracks found</div>
    }
    return <>
        <div>
            <FlatList
                    list={tracksByMostRecentSession}
                    renderItem={renderItem}
                    renderWhenEmpty={showBlank}
                    style={{overflow: "auto", height: "300px"}}
                    hasMoreItems={hasMoreItems}
                    loadMoreItems={loadMoreData}
                    displayGrid
                    minColumnWidth='400px'
            />
            <div style={{position: 'absolute', right: '1px', bottom: '1px', color: 'gray', fontSize: '10pt'}} >1.0.0</div>
        </div>
    </>;
}

export default Home;
