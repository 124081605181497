import React from "react";
import {FloatingLabel, Form} from "react-bootstrap";
import {FormikProps} from "formik";

export interface ValidatedFormControlProps {
    label: string,
    type: string,
    placeholder: string,
    formikProps: FormikProps<any>,
    field: string,
    children?: JSX.Element
}

const ValidatedFormControl = ({label, type, placeholder, formikProps, field, children}: ValidatedFormControlProps) => {
    return (
            <Form.Group>
                <FloatingLabel
                        label={label}
                >
                    <Form.Control
                            type={type}
                                  placeholder={placeholder}
                                  name={field}
                                  value={formikProps.values[field]}
                                  onChange={formikProps.handleChange}
                                  onBlur={formikProps.handleBlur}
                                  isValid={formikProps.touched[field] && !formikProps.errors[field]}
                                  isInvalid={formikProps.touched[field] && !!formikProps.errors[field]}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formikProps.errors[field] as string}
                    </Form.Control.Feedback>
                    {children}
                </FloatingLabel>
            </Form.Group>
    );
};

export default ValidatedFormControl;
