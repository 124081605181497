import {useAppDispatch, useAppSelector} from "redux/hooks";
import {
    selectSelectedTrack
} from "redux/features/firestore/tracks/slice";
import {useCallback, useEffect, useState} from "react";
import {
    getSessionsForTrack,
    selectSessionsInTrack, selectSessionsInTrackHasMoreItems,
} from "redux/features/firestore/sessions/slice";
import Session from "redux/features/firestore/sessions/Session";
import {selectBackendLoading} from "redux/features/firestore/slice";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Lap from "redux/features/firestore/sessions/Lap";
import {Button} from "react-bootstrap";
import SessionsListTreeItem from "./SessionsList/SessionsListTreeItem";
import SessionTreeItem from "./SessionsList/SessionTreeItem";
import LapTreeItem from "./SessionsList/LapTreeItem";

const SessionsList = () => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(selectBackendLoading);
    const selectedTrack = useAppSelector(selectSelectedTrack);
    const sessionsInTrack = useAppSelector(selectSessionsInTrack);
    const hasMoreItems = useAppSelector(selectSessionsInTrackHasMoreItems);

    const loadMoreData = useCallback(() => {
        dispatch(getSessionsForTrack(selectedTrack));
    }, [dispatch, selectedTrack]);
    useEffect(() => {
        if (selectedTrack && (!sessionsInTrack || sessionsInTrack.length === 0)) {
            loadMoreData();
        }
    }, [dispatch, loadMoreData, selectedTrack, sessionsInTrack])

    const [bestSession, setBestSession] = useState<Session | undefined>(undefined);
    useEffect(() => {
        let currentBestSession: Session | undefined = undefined;
        if (sessionsInTrack) {
            for (const sessionInTrack of sessionsInTrack) {
                if (!currentBestSession || (sessionInTrack.bestLap && sessionInTrack.bestLap.time < currentBestSession.bestLap!.time)) {
                    currentBestSession = sessionInTrack;
                }
            }
        }

        setBestSession(currentBestSession);
    }, [sessionsInTrack])

    return (<>
        {
            !sessionsInTrack ? loading ? <div>Loading sessions...</div> : <div>No session found</div> :
                    <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon/>}
                            defaultExpandIcon={<ChevronRightIcon/>}
                    >
                        {
                            sessionsInTrack.map((session: Session) => {
                                return (
                                        <TreeItem key={session.id} nodeId={session.id}
                                                  ContentComponent={SessionsListTreeItem}
                                                  label={<SessionTreeItem session={session} bestSession={bestSession}/>}
                                        >
                                            {
                                                session.laps.filter((lap: Lap) => {
                                                    return !lap.deleted;
                                                }).map((lap: Lap) => {
                                                    return (
                                                            <TreeItem key={`${session.id}.${lap.originalNumber}`}
                                                                      nodeId={`${session.id}.${lap.originalNumber}`}
                                                                      ContentComponent={SessionsListTreeItem}
                                                                      label={<LapTreeItem lap={lap} session={session}/>}
                                                            />
                                                    );
                                                })
                                            }
                                        </TreeItem>
                                );
                            })
                        }
                    </TreeView>
        }
        {hasMoreItems ? <div className="d-grid gap-2">
            <Button variant="outline-primary" size="sm" disabled={loading} onClick={loadMoreData}>
                Load more sessions
            </Button>
        </div> : null}
    </>);
}

export default SessionsList;
