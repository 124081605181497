import Graph from "./Graph";
import Lap from "redux/features/firestore/sessions/Lap";
import {ShownLap} from "redux/features/firestore/sessions/slice";

const TimeDiffGraph = () => {
    return Graph((index: number, fastestLap: Lap, shownLap: ShownLap): number => {
                let fastestLocation = fastestLap.locations![index].location;
                let lapLocation = shownLap.calculatedLocations![index].location;

                const fastestTime = fastestLocation.unixTimestampUTC - fastestLap.locations![0].location.unixTimestampUTC;
                const lapTime = lapLocation.unixTimestampUTC - shownLap.calculatedLocations![0].location.unixTimestampUTC;

                return (lapTime - fastestTime) / 1000.0;
            },
            (value: number) => `${value.toFixed(3)}`,
            's',
            false,
            20,
            true);
}
export default TimeDiffGraph;
