import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {QueryParamProvider} from "use-query-params";
import {ReactRouter6Adapter} from "use-query-params/adapters/react-router-6";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

require('react-web-vector-icons/fonts');

const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
);

root.render(
        <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <App/>
            </QueryParamProvider>
        </BrowserRouter>
);

reportWebVitals();
